<template>
	<div>

		<!------------------->
		<!---[ CONTENIDO ]--->
		<!------------------->
		<b-container class="mb-5">

			<!-- CABECERA -->
			<b-row no-gutters>
				<b-col sm="auto" class="pr-3">
					<b-img src="/img/logo_hmc.svg" style="height: 60px;" class="mt-1"></b-img>
				</b-col>
				<b-col>
					<p class="fw-bold text-size-2 mb-0">Acreditación</p>
					<p class="text-size-14 mb-3">Conozca el avance detallado de cualquier empresa colaboradora.</p>
				</b-col>
			</b-row>

			<!-- MENÚ -->
			<b-row>
				<b-col sm="auto">
					<p class="fw-bold text-size-11 mb-2">Acciones Rápidas</p>
					<b-row class="mb-4" no-gutters>
						<b-col sm="auto" class="mr-2">
							<b-button variant="outline-dark" @click="openSearch()">
								<i class="fas fa-search mr-1"></i>
								<span>Buscar</span>
							</b-button>
						</b-col>
						<b-col sm="auto">
							<b-button variant="outline-dark" @click="reload()">
								<i class="fas fa-rotate-right mr-1"></i>
								<span>Recargar</span>
							</b-button>
						</b-col>
					</b-row>
				</b-col>
				<b-col>
					<p class="fw-bold text-size-11 mb-2">Nuevos Registros</p>
					<b-row class="mb-4" no-gutters>
						<b-col sm="auto" class="mr-2">
							<b-button variant="outline-dark" @click="newCompany()">
								<i class="fas fa-plus mr-1"></i>
								<span>Empresa</span>
							</b-button>
						</b-col>
						<b-col sm="auto" class="mr-2">
							<b-button variant="outline-dark" @click="newService()">
								<i class="fas fa-plus mr-1"></i>
								<span>Servicio</span>
							</b-button>
						</b-col>
						<b-col sm="auto" class="mr-2">
							<b-button variant="outline-dark" @click="newWorker()">
								<i class="fas fa-plus mr-1"></i>
								<span>Persona</span>
							</b-button>
						</b-col>
						<b-col sm="auto">
							<b-button variant="outline-dark" @click="newVehicle()">
								<i class="fas fa-plus mr-1"></i>
								<span>Vehículo</span>
							</b-button>
						</b-col>
					</b-row>
				</b-col>
			</b-row>

			<!-- LISTADO -->
			<b-skeleton-wrapper :loading="loading">

				<!-- RELLENO-->
				<template #loading>
					<p class="fw-bold mb-2">
						<b-spinner type="grow" small class="mr-2" variant="primary"></b-spinner>
						<span class="text-size-14">Cargando</span>
					</p>
					<b-list-group>
						<b-list-group-item class="px-3 py-3" v-for="(item, index) in [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]" :key="index">
							<b-skeleton class="mb-0"></b-skeleton>
						</b-list-group-item>
					</b-list-group>
				</template>

				<!-- CONTENIDO -->
				<div>
					<p class="fw-bold text-size-14 mb-2">{{ items.length ? items.length : 0 }} Empresas Vigentes</p>
					<b-list-group>
						<b-list-group-item v-for="(item, index) in items" :key="index" button class="px-3 py-3">
							<b-row @click="toggleCollapse(index)">
								<b-col>
									<p class="mb-0 nowrap text-size-12">
										<i class="fas fa-check-circle text-success" v-if="item.full_done == true"></i>
										<i class="fas fa-times-circle text-danger" v-else></i>
										<span class="ml-2 text-uppercase fw-5">{{ item.short_name }}</span>
									</p>
								</b-col>
								<b-col sm="auto" class="text-right">
									<small class="text-uppercase text-muted fw-4">{{ item.full_name }}</small>
								</b-col>
							</b-row>
							<b-collapse :id="`collapse${index}`" class="pl-0 mt-0">
								<div v-if="index === 0">
									<b-row class="mb-3">
										<b-col sm="auto" class="pr-0">
											<p class="mb-0 text-size-10 text-muted">
												<i class="far fa-fw fa-building mr-1"></i>
												<span>{{ parseIdentifier(item.identifier) }}</span>
											</p>
										</b-col>
										<b-col sm="auto" class="pr-0">
											<p class="mb-0 text-lowercase text-size-10 text-muted">
												<i class="far fa-fw fa-envelope mr-1"></i>
												<span>{{ item.email }}</span>
											</p>
										</b-col>
										<b-col sm="auto" class="pr-0">
											<p class="mb-0 text-size-10 text-muted">
												<i class="far fa-fw fa-phone mr-1"></i>
												<span>{{ item.phone }}</span>
											</p>
										</b-col>
										<b-col sm="auto">
											<p class="mb-0 text-size-10 text-muted text-uppercase">
												<i class="far fa-fw fa-location-dot mr-1"></i>
												<span>{{ item.address }}, </span>
												<span v-if="item.commune != null && item.commune.length > 0">{{ item.commune }}</span>
												<span v-else-if="item.commune_id != null && item.commune_rel != null && item.commune_rel.name">{{ item.commune_rel.name }}</span>
												<span v-else>Comuna No Informada</span>
											</p>
										</b-col>
										<b-col class="text-right">
										</b-col>
									</b-row>
									<b-row>
										<b-col>
											<b-card header-class="px-3 py-2" header-bg-variant="success" header-text-variant="white" body-class="px-3 py-3">
												<template #header>
													<i class="fas fa-check-circle mr-1"></i>
													<span>Abastecimiento y Contratos</span>
												</template>
												<b-button block variant="outline-success" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-check mr-1"></i>
													<span>Empresa Acreditada</span>
												</b-button>
												<b-button block variant="outline-success" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-check mr-1"></i>
													<span>{{ item.services.length }} Servicios Acreditados</span>
												</b-button>
											</b-card>
										</b-col>
										<b-col sm="auto" class="p-0">
											<i class="fas fa-chevron-right text-size-13 mt-3 text-success"></i>
										</b-col>
										<b-col>
											<b-card header-class="px-3 py-2" header-bg-variant="success" header-text-variant="white" body-class="px-3 py-3">
												<template #header>
													<i class="fas fa-check-circle mr-1"></i>
													<span>Recursos Humanos</span>
												</template>
												<b-button block variant="outline-success" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-check mr-1"></i>
													<span>43 Personas Acreditadas</span>
												</b-button>
												<b-button block variant="outline-success" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-check mr-1"></i>
													<span>43 Personas en Nómina</span>
												</b-button>
											</b-card>
										</b-col>
										<b-col sm="auto" class="p-0">
											<i class="fas fa-chevron-right text-size-13 mt-3 text-success"></i>
										</b-col>
										<b-col>
											<b-card header-class="px-3 py-2" header-bg-variant="success" header-text-variant="white" body-class="px-3 py-3">
												<template #header>
													<i class="fas fa-check-circle mr-1"></i>
													<span>Prevención de Riesgos</span>
												</template>
												<b-button block variant="outline-success" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-check mr-1"></i>
													<span>Documentación SST</span>
												</b-button>
												<b-button block variant="outline-success" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-check mr-1"></i>
													<span>Carpeta de Arranque</span>
												</b-button>
												<b-button block variant="outline-success" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-check mr-1"></i>
													<span>Reunión de Arranque</span>
												</b-button>
											</b-card>
										</b-col>
										<b-col>
											<b-card header-class="px-3 py-2" header-bg-variant="success" header-text-variant="white" body-class="px-3 py-3">
												<template #header>
													<i class="fas fa-check-circle mr-1"></i>
													<span>Protección Industrial</span>
												</template>
												<b-button block variant="outline-success" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-check mr-1"></i>
													<span>8 Licencias Internas</span>
												</b-button>
												<b-button block variant="outline-success" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-check mr-1"></i>
													<span>4 Vehículos Motorizados</span>
												</b-button>
											</b-card>
										</b-col>
									</b-row>

									<b-row v-if="index == 0" class="mt-4">
										<b-col>
											<b-card header-class="px-3 py-2" header-bg-variant="danger" header-text-variant="white" body-class="px-3 py-3">
												<template #header>
													<i class="fas fa-exclamation-triangle mr-1"></i>
													<span>Abastecimiento y Contratos</span>
												</template>
												<b-button block variant="outline-danger" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
													<span>Empresa No Acreditada</span>
												</b-button>
												<b-button block variant="outline-danger" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
													<span>{{ item.services.length }} Servicios No Acreditados</span>
												</b-button>
											</b-card>
										</b-col>
										<b-col sm="auto" class="p-0">
											<i class="fas fa-chevron-right text-size-13 mt-3 text-danger"></i>
										</b-col>
										<b-col>
											<b-card header-class="px-3 py-2" header-bg-variant="danger" header-text-variant="white" body-class="px-3 py-3">
												<template #header>
													<i class="fas fa-exclamation-triangle mr-1"></i>
													<span>Recursos Humanos</span>
												</template>
												<b-button block variant="outline-danger" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
													<span>43 Personas No Acreditadas</span>
												</b-button>
												<b-button block variant="outline-danger" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
													<span>43 Personas Sin  Nómina</span>
												</b-button>
											</b-card>
										</b-col>
										<b-col sm="auto" class="p-0">
											<i class="fas fa-chevron-right text-size-13 mt-3 text-danger"></i>
										</b-col>
										<b-col>
											<b-card header-class="px-3 py-2" header-bg-variant="danger" header-text-variant="white" body-class="px-3 py-3">
												<template #header>
													<i class="fas fa-exclamation-triangle mr-1"></i>
													<span>Prevención de Riesgos</span>
												</template>
												<b-button block variant="outline-danger" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
													<span>Documentación SST</span>
												</b-button>
												<b-button block variant="outline-danger" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
													<span>Carpeta de Arranque</span>
												</b-button>
												<b-button block variant="outline-danger" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
													<span>Reunión de Arranque</span>
												</b-button>
											</b-card>
										</b-col>
										<b-col>
											<b-card header-class="px-3 py-2" header-bg-variant="danger" header-text-variant="white" body-class="px-3 py-3">
												<template #header>
													<i class="fas fa-exclamation-triangle mr-1"></i>
													<span>Protección Industrial</span>
												</template>
												<b-button block variant="outline-danger" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
													<span>4 Licencias Pendientes</span>
												</b-button>
												<b-button block variant="outline-danger" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
													<span>2 Vehículos No Acreditados</span>
												</b-button>
											</b-card>
										</b-col>
									</b-row>

									<b-row class="mt-4">
										<b-col>
											<b-card header-class="px-3 py-2" header-bg-variant="secondary" header-text-variant="white" body-class="px-3 py-3">
												<template #header>
													<i class="fas fa-folder-open mr-1"></i>
													<span>Abastecimiento y Contratos</span>
												</template>
												<b-button block variant="outline-secondary" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-upload mr-1"></i>
													<span>Acreditar Empresa</span>
												</b-button>
												<b-button block variant="outline-secondary" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-upload mr-1"></i>
													<span>Acreditar Servicios</span>
												</b-button>
											</b-card>
										</b-col>
										<b-col sm="auto" class="p-0">
											<i class="fas fa-chevron-right text-size-13 mt-3 text-secondary"></i>
										</b-col>
										<b-col>
											<b-card header-class="px-3 py-2" header-bg-variant="secondary" header-text-variant="white" body-class="px-3 py-3">
												<template #header>
													<i class="fas fa-folder-open mr-1"></i>
													<span>Recursos Humanos</span>
												</template>
												<b-button block variant="outline-secondary" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-upload mr-1"></i>
													<span>Acreditar Personas</span>
												</b-button>
												<b-button block variant="outline-secondary" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-upload mr-1"></i>
													<span>Activar Personas</span>
												</b-button>
											</b-card>
										</b-col>
										<b-col sm="auto" class="p-0">
											<i class="fas fa-chevron-right text-size-13 mt-3 text-secondary"></i>
										</b-col>
										<b-col>
											<b-card header-class="px-3 py-2" header-bg-variant="secondary" header-text-variant="white" body-class="px-3 py-3">
												<template #header>
													<i class="fas fa-folder-open mr-1"></i>
													<span>Prevención de Riesgos</span>
												</template>
												<b-button block variant="outline-secondary" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-upload mr-1"></i>
													<span>Cargar Documentación SST</span>
												</b-button>
												<b-button block variant="outline-secondary" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-upload mr-1"></i>
													<span>Cargar Carpeta de Arranque</span>
												</b-button>
												<b-button block variant="outline-secondary" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-upload mr-1"></i>
													<span>Cargar Reunión de Arranque</span>
												</b-button>
											</b-card>
										</b-col>
										<b-col>
											<b-card header-class="px-3 py-2" header-bg-variant="secondary" header-text-variant="white" body-class="px-3 py-3">
												<template #header>
													<i class="fas fa-folder-open mr-1"></i>
													<span>Protección Industrial</span>
												</template>
												<b-button block variant="outline-secondary" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-upload mr-1"></i>
													<span>Acreditar Conductores</span>
												</b-button>
												<b-button block variant="outline-secondary" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-upload mr-1"></i>
													<span>Acreditar Vehículos</span>
												</b-button>
											</b-card>
										</b-col>
									</b-row>

									<b-row class="mt-4">
										<b-col>
											<b-card header-class="px-3 py-2" header-bg-variant="light" header-text-variant="secondary" body-class="px-3 py-3">
												<template #header>
													<span>Abastecimiento y Contratos</span>
												</template>
												<b-alert show variant="warning" class="px-2 py-1">
													<p class="mb-0 text-size-09">Documentos de esta etapa son los primeros en ser revisados.</p>
												</b-alert>
												<b-button block variant="outline-info" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-info-circle mr-1"></i>
													<span>Información de Requisitos</span>
												</b-button>
											</b-card>
										</b-col>
										<b-col sm="auto" class="p-0">
											<i class="fas fa-chevron-right text-size-13 mt-3 text-muted"></i>
										</b-col>
										<b-col>
											<b-card header-class="px-3 py-2" header-bg-variant="light" header-text-variant="secondary" body-class="px-3 py-3">
												<template #header>
													<span>Recursos Humanos</span>
												</template>
												<b-alert show variant="warning" class="px-2 py-1">
													<p class="mb-0 text-size-09">Documentos de esta etapa no son revisados hasta completar Abastecimiento y Contratos.</p>
												</b-alert>
												<b-button block variant="outline-info" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-info-circle mr-1"></i>
													<span>Información de Requisitos</span>
												</b-button>
											</b-card>
										</b-col>
										<b-col sm="auto" class="p-0">
											<i class="fas fa-chevron-right text-size-13 mt-3 text-muted"></i>
										</b-col>
										<b-col>
											<b-card header-class="px-3 py-2" header-bg-variant="light" header-text-variant="secondary" body-class="px-3 py-3">
												<template #header>
													<span>Prevención de Riesgos</span>
												</template>
												<b-alert show variant="warning" class="px-2 py-1">
													<p class="mb-0 text-size-09">Documentos de esta etapa no son revisados hasta completar Recursos Humanos.</p>
												</b-alert>
												<b-button block variant="outline-info" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-info-circle mr-1"></i>
													<span>Información de Requisitos</span>
												</b-button>
											</b-card>
										</b-col>
										<b-col>
											<b-card header-class="px-3 py-2" header-bg-variant="light" header-text-variant="secondary" body-class="px-3 py-3">
												<template #header>
													<span>Protección Industrial</span>
												</template>
												<b-alert show variant="warning" class="px-2 py-1">
													<p class="mb-0 text-size-09">Documentos de esta etapa no son revisados hasta completar Prevención de Riesgos.</p>
												</b-alert>
												<b-button block variant="outline-info" class="text-left py-2" size="sm">
													<i class="fas fa-fw fa-info-circle mr-1"></i>
													<span>Información de Requisitos</span>
												</b-button>
											</b-card>
										</b-col>
									</b-row>
								</div>
								<b-row class="mb-3" v-if="index != 0">
									<b-col sm="auto" class="pr-0">
										<p class="mb-0 text-size-10 text-muted">
											<i class="far fa-fw fa-building mr-1"></i>
											<span>{{ parseIdentifier(item.identifier) }}</span>
										</p>
									</b-col>
									<b-col sm="auto" class="pr-0">
										<p class="mb-0 text-lowercase text-size-10 text-muted">
											<i class="far fa-fw fa-envelope mr-1"></i>
											<span>{{ item.email }}</span>
										</p>
									</b-col>
									<b-col sm="auto" class="pr-0">
										<p class="mb-0 text-size-10 text-muted">
											<i class="far fa-fw fa-phone mr-1"></i>
											<span>{{ item.phone }}</span>
										</p>
									</b-col>
									<b-col>
										<p class="mb-0 text-size-10 text-muted text-uppercase">
											<i class="far fa-fw fa-location-dot mr-1"></i>
											<span>{{ item.address }}, </span>
											<span v-if="item.commune != null && item.commune.length > 0">{{ item.commune }}</span>
											<span v-else-if="item.commune_id != null && item.commune_rel != null && item.commune_rel.name">{{ item.commune_rel.name }}</span>
											<span v-else>Comuna No Informada</span>
										</p>
									</b-col>
								</b-row>
								<b-row v-if="index != 0">
									<b-col>
										<b-card header-class="px-3 py-2" :header-bg-variant="item.stage1.company_done == true && item.stage1.services_all_done == true ? 'success' : 'secondary'" header-text-variant="white" body-class="px-3 py-3">
											<template #header>
												<i class="fas fa-check-circle mr-1" v-if="item.stage1.company_done == true && item.stage1.services_all_done == true"></i>
												<i class="fas fa-exclamation-triangle mr-1" v-else></i>
												<span>Abastecimiento y Contratos</span>
											</template>
											<b-button block variant="outline-success" class="text-left py-2" size="sm" v-if="item.stage1.company_done === true" @click="aycCompany()">
												<i class="fas fa-fw fa-check mr-1"></i>
												<span>Empresa Acreditada</span>
											</b-button>
											<b-button block variant="danger" class="text-left py-2" size="sm" v-else @click="aycCompany()">
												<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
												<span>Empresa No Acreditada</span>
											</b-button>
											<b-button block variant="outline-success" class="text-left py-2" size="sm" v-if="item.stage1.services_all_done === true" @click="goTo('Vista1')">
												<i class="fas fa-fw fa-check mr-1"></i>
												<span v-if="item.stage1.services_count == 1">1 Servicio Acreditado</span>
												<span v-else>{{ item.stage1.services_count }} Servicios Acreditados</span>
											</b-button>
											<b-button block variant="danger" class="text-left py-2" size="sm" v-else @click="goTo('Vista1')">
												<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
												<span v-if="item.stage1.services_count === 0">Sin Servicios Registrados</span>
												<span v-else-if="item.stage1.services_count == 1">1 Servicio No Acreditado</span>
												<span v-else>{{ item.stage1.services_count - item.stage1.services_done_count }} de {{ item.stage1.services_count }} Servicios No Acreditados</span>
											</b-button>
										</b-card>
									</b-col>

									<b-col sm="auto" class="p-0">
										<i class="fas fa-chevron-right text-size-13 mt-3" :class="{
											'text-success' : item.stage1.company_done == true && item.stage1.services_all_done == true,
											'text-secondary' : item.stage1.company_done == false || item.stage1.services_all_done == false
										}"></i>
									</b-col>

									<b-col>
										<b-card header-class="px-3 py-2" :header-bg-variant="item.stage2.workers_all_done === true ? 'success' : 'secondary'" header-text-variant="white" body-class="px-3 py-3">
											<template #header>
												<i class="fas fa-check-circle mr-1" v-if="item.stage2.workers_all_done == true"></i>
												<i class="fas fa-exclamation-triangle mr-1" v-else></i>
												<span>Recursos Humanos</span>
											</template>
											<b-button block variant="outline-success" class="text-left py-2" size="sm" v-if="item.stage2.workers_all_done === true" @click="goTo('Vista2')">
												<i class="fas fa-fw fa-check mr-1"></i>
												<span v-if="item.stage2.workers_count == 1">1 Persona Acreditada</span>
												<span v-else>{{ item.stage2.workers_count }} Personas Acreditadas</span>
											</b-button>
											<b-button block variant="danger" class="text-left py-2" size="sm" v-else @click="goTo('Vista2')">
												<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
												<span v-if="item.stage2.workers_count == 0">Sin Personas Registradas</span>
												<span v-else-if="item.stage2.workers_count == 1">1 Persona No Acreditada</span>
												<span v-else>{{ item.stage2.workers_count - item.stage2.workers_done }} de {{ item.stage2.workers_count }} Personas No Acreditadas</span>
											</b-button>
										</b-card>
									</b-col>

									<b-col sm="auto" class="p-0">
										<i class="fas fa-chevron-right text-size-13 mt-3" :class="{
											'text-success' : item.stage2.workers_all_done == true && item.stage1.company_done == true && item.stage1.services_all_done == true,
											'text-secondary' : item.stage2.workers_all_done == false || item.stage1.company_done == false || item.stage1.services_all_done == false,
										}"></i>
									</b-col>

									<b-col>
										<b-card header-class="px-3 py-2" :header-bg-variant="item.stage3.full_done === true ? 'success' : 'secondary'" header-text-variant="white" body-class="px-3 py-3">
											<template #header>
												<i class="fas fa-check-circle mr-1" v-if="item.stage3.full_done == true"></i>
												<i class="fas fa-exclamation-triangle mr-1" v-else></i>
												<span>Prevención de Riesgos</span>
											</template>
											<b-button block :variant="item.stage3.people_done ? 'outline-success' : 'danger'" class="text-left py-2" size="sm" @click="goTo('Vista3')">
												<i class="fas fa-check mr-1" v-if="item.stage3.people_done == true"></i>
												<i class="fas fa-fw fa-exclamation-triangle mr-1" v-else></i>
												<span>Documentación SST</span>
											</b-button>
											<b-button block :variant="item.stage3.archive_done ? 'outline-success' : 'danger'" class="text-left py-2" size="sm" @click="dprArchive()">
												<i class="fas fa-check mr-1" v-if="item.stage3.archive_done == true"></i>
												<i class="fas fa-fw fa-exclamation-triangle mr-1" v-else></i>
												<span>Carpeta de Arranque</span>
											</b-button>
											<b-button block :variant="item.stage3.meeting_done ? 'outline-success' : 'danger'" class="text-left py-2" size="sm" @click="dprMeeting()">
												<i class="fas fa-check mr-1" v-if="item.stage3.meeting_done == true"></i>
												<i class="fas fa-fw fa-exclamation-triangle mr-1" v-else></i>
												<span>Reunión de Arranque</span>
											</b-button>
										</b-card>
									</b-col>

									<b-col>
										<b-card header-class="px-3 py-2" :header-bg-variant="item.stage4.drivers_all_done === true && item.stage4.vehicles_all_done === true ? 'success' : 'secondary'" header-text-variant="white" body-class="px-3 py-3">
											<template #header>
												<i class="fas fa-check-circle mr-1" v-if="item.stage4.drivers_all_done == true"></i>
												<i class="fas fa-exclamation-triangle mr-1" v-else></i>
												<span>Protección Industrial</span>
											</template>
											<b-button block variant="outline-success" class="text-left py-2" size="sm" v-if="item.stage4.drivers_all_done === true" @click="openDrivers(item)">
												<i class="fas fa-fw fa-check mr-1"></i>
												<span v-if="item.stage4.drivers_count == 1">1 Conductor Acreditado</span>
												<span v-else>{{ item.stage4.drivers_count }} Conductores Acreditados</span>
											</b-button>
											<b-button block variant="danger" class="text-left py-2" size="sm" v-else @click="openDrivers(item)">
												<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
												<span v-if="item.stage4.drivers_count == 0">Sin Conductores Registrados</span>
												<span v-else-if="item.stage4.drivers_count == 1">1 Conductor No Acreditado</span>
												<span v-else>{{ item.stage4.drivers_count - item.stage4.drivers_done }} de {{ item.stage4.drivers_count }} Conductores No Acreditados</span>
											</b-button>
											<b-button block variant="outline-success" class="text-left py-2" size="sm" v-if="item.stage4.vehicles_all_done === true" @click="openVehicles(item)">
												<i class="fas fa-fw fa-check mr-1"></i>
												<span v-if="item.stage4.vehicles_count == 1">1 Vehículo Acreditado</span>
												<span v-else>{{ item.stage4.vehicles_count }} Vehículos Acreditados</span>
											</b-button>
											<b-button block variant="danger" class="text-left py-2" size="sm" v-else @click="openVehicles(item)">
												<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
												<span v-if="item.stage4.vehicles_count == 0">Sin Vehículos Registrados</span>
												<span v-else-if="item.stage4.vehicles_count == 1">1 Vehículo No Acreditado</span>
												<span v-else>{{ item.stage4.vehicles_count - item.stage4.vehicles_done }} de {{ item.stage4.vehicles_count }} Vehículos No Acreditados</span>
											</b-button>
										</b-card>
									</b-col>

								</b-row>
							</b-collapse>
						</b-list-group-item>
					</b-list-group>
				</div>
			</b-skeleton-wrapper>

		</b-container>


		<!----------------->
		<!---[ MODALES ]--->
		<!----------------->

		<!-- MODAL: BÚSQUEDA -->
		<b-modal
			id="new-search"
			title="Búsqueda"
			hide-footer
		>
			<b-alert show variant="danger" class="mb-0">
				<p class="mb-0 text-size-12">Módulo no cargado.</p>
			</b-alert>
		</b-modal>

		<!-- MODAL: EMPRESA -->
		<b-modal
			id="new-company"
			title="Nueva Empresa"
			hide-footer
		>
			<b-alert show variant="danger" class="mb-0">
				<p class="mb-0 text-size-12">Módulo no cargado.</p>
			</b-alert>
		</b-modal>

		<!-- MODAL: SERVICIO -->
		<b-modal
			id="new-service"
			title="Nuevo Servicio"
			hide-footer
		>
			<b-alert show variant="danger" class="mb-0">
				<p class="mb-0 text-size-12">Módulo no cargado.</p>
			</b-alert>
		</b-modal>

		<!-- MODAL: PERSONA -->
		<b-modal
			id="new-worker"
			title="Nueva Persona"
			hide-footer
		>
			<b-alert show variant="danger" class="mb-0">
				<p class="mb-0 text-size-12">Módulo no cargado.</p>
			</b-alert>
		</b-modal>

		<!-- MODAL: VEHÍCULO -->
		<b-modal
			id="new-vehicle"
			title="Nuevo Vehículo"
			size="md"
		>
			<b-row>
				<b-col sm="6">
					<b-form-group label="Placa Patente Única">
						<b-row no-gutters>
							<b-col class="pr-1">
								<b-input style="width: 30px;" class="px-0 text-center"></b-input>
							</b-col>
							<b-col class="pr-1">
								<b-input style="width: 30px;" class="px-0 text-center"></b-input>
							</b-col>
							<b-col class="pr-1">
								<b-input style="width: 30px;" class="px-0 text-center"></b-input>
							</b-col>
							<b-col class="pr-1">
								<b-input style="width: 30px;" class="px-0 text-center"></b-input>
							</b-col>
							<b-col class="pr-1">
								<b-input style="width: 30px;" class="px-0 text-center"></b-input>
							</b-col>
							<b-col>
								<b-input style="width: 30px;" class="px-0 text-center"></b-input>
							</b-col>
						</b-row>
					</b-form-group>
				</b-col>
				<b-col sm="6">
					<b-form-group label="Tipo de Vehículo">
						<b-select v-model="vehicle_type" :options="[
							{ value: 1, text: 'AMBULANCIA' },
							{ value: 2, text: 'AUTOMÓVIL' },
							{ value: 3, text: 'CAMIÓN' },
							{ value: 4, text: 'CAMIÓN CISTERNA' },
							{ value: 5, text: 'CAMIÓN GRÚA' },
							{ value: 6, text: 'CAMIONETA' },
							{ value: 7, text: 'CARRO BOMBA' },
							{ value: 8, text: 'CARRO DE ARRASTRE' },
							{ value: 9, text: 'CASA RODANTE' },
							{ value: 10, text: 'CHASIS CABINADO' },
							{ value: 11, text: 'CHASSIS' },
							{ value: 12, text: 'FURGON' },
							{ value: 13, text: 'FURGON DE CARGA' },
							{ value: 14, text: 'FURGON PESADO' },
							{ value: 15, text: 'JEEP' },
							{ value: 16, text: 'MINIBUS' },
							{ value: 17, text: 'MOTOCICLETA' },
							{ value: 18, text: 'STATION WAGON' },
							{ value: 19, text: 'TAXI' },
							{ value: 20, text: 'TRACTO CAMIÓN' },
							{ value: 21, text: 'UTILITARIO' },
							{ value: 22, text: 'MAQUINARIA PESADA' },
							{ value: 23, text: 'OTRO' },
						]">
						</b-select>
					</b-form-group>
				</b-col>
				<b-col sm="12">
					<b-alert show variant="info">
						<p class="mb-0">El tipo de vehículo seleccionado requiere cargar la siguiente documentación:</p>
						<ul>
							<li>Requisito 1</li>
							<li>Requisito 2</li>
							<li>Requisito 3</li>
							<li>Requisito 4</li>
							<li>Requisito 5</li>
							<li>Requisito 6</li>
						</ul>
					</b-alert>
				</b-col>
				<b-col sm="6">
					<b-form-group label="Marca">
						<b-input></b-input>
					</b-form-group>
				</b-col>
				<b-col sm="6">
					<b-form-group label="Modelo">
						<b-input></b-input>
					</b-form-group>
				</b-col>
				<b-col sm="6">
					<b-form-group label="Año">
						<b-input></b-input>
					</b-form-group>
				</b-col>
				<b-col sm="6">
					<b-form-group label="N° Serie Motor">
						<b-input></b-input>
					</b-form-group>
				</b-col>
			</b-row>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary">Cancelar</b-button>
				<b-button variant="success">CONFIRMAR</b-button>
			</template>
		</b-modal>

		<!-- MODAL: PROTECCIÓN INDUSTRIAL / CONDUCTORES -->
		<b-modal
			id="drivers-manager"
			title="Conductores"
			size="xl"
			hide-footer
			class="p-0"
		>
			<b-table
				:items="current.drivers"
				:fields="[
					{ key: 'person.identifier', label: 'RUT', sortable: true },
					{ key: 'person.names', label: 'Nombres', sortable: true },
					{ key: 'person.primary_last_name', label: '1er Apellido', sortable: true },
					{ key: 'person.second_last_name', label: '2do Apellido', sortable: true },
					{ key: 'company.short_name', label: 'Empresa', sortable: true },
					{ key: 'req_driver_done', label: 'Acreditado', sortable: true },
					{ key: 'docs', label: 'Licencia', sortable: true },
				]"
				hover
				bordered
			>
				<template #cell(person.identifier)="data">
					{{ parseIdentifier(data.value) }}
				</template>
				<template #cell(docs)="data">
					<div class="text-center" v-if="data.item.req_driver_done == 6">
						<div v-if="data.index % 2 == 0">
							<b-button block variant="danger" @click="openDriversLicense(data.item)" size="sm">
								<i class="fas fa-exclamation-triangle mr-1"></i>
								<span>Solicitar</span>
							</b-button>
						</div>
						<div v-else>
							<i class="fas fa-check text-success"></i>
						</div>
					</div>
					<div class="text-center" v-else>
						<b-button block disabled variant="secondary" size="sm">Solicitar</b-button>
					</div>
				</template>
				<template #cell(req_driver_done)="data">
					<div class="text-center" v-if="data.value == 6">
						<b-button block variant="outline-success" size="sm">
							<i class="fas fa-check mr-1"></i>
							<span>6 Requisitos</span>
						</b-button>
					</div>
					<div class="text-center" v-else>
						<i class="fas fa-exclamation-triangle text-danger"></i>
						{{ 6 - data.value }} Pendiente<span v-if="data.value === 1">s</span>
					</div>
				</template>
			</b-table>
		</b-modal>

		<!-- MODAL: PROTECCION INDUSTRIAL / GENERADOR LICENCIAS -->
		<b-modal
			id="drivers-license-generator"
			title="Solicitud de Licencia Interna de Conducción HMC Faena Michilla - EECC"
			size="lg"
		>

			<div v-if="current.driver && current.driver.id">
				<b-card no-body class="mb-4">
					<b-card-header>
						<p class="mb-0 fw-5 text-size-12">Información Personal del Conductor</p>
					</b-card-header>
					<b-card-body class="pb-0">
						<b-row>
							<b-col md="3">
								<b-form-group label="RUT" class="text-uppercase" :label-class="form_label_class">
									{{ parseIdentifier(current.driver.person.identifier) }}
								</b-form-group>
							</b-col>
							<b-col md="3">
								<b-form-group label="Nombres" class="text-uppercase" :label-class="form_label_class">
									{{ current.driver.person.names }}
								</b-form-group>
							</b-col>
							<b-col md="3">
								<b-form-group label="1er Apellido" class="text-uppercase" :label-class="form_label_class">
									{{ current.driver.person.primary_last_name }}
								</b-form-group>
							</b-col>
							<b-col md="3">
								<b-form-group label="2do Apellido" class="text-uppercase" :label-class="form_label_class">
									{{ current.driver.person.second_last_name }}
								</b-form-group>
							</b-col>
							<b-col md="6">
								<b-form-group label="Cargo" class="text-uppercase" :label-class="form_label_class">
									{{ current.driver.position }}
								</b-form-group>
							</b-col>
							<b-col md="6">
								<b-form-group label="Empresa" class="text-uppercase" :label-class="form_label_class">
									{{ current.driver.company.short_name }}
								</b-form-group>
							</b-col>
						</b-row>
						<hr class="mt-0">
						<b-row>
							<b-col md="3">
								<b-form-group label="Licencia Municipal" :label-class="form_label_class">
									<b-row>
										<b-col>
											<b-form-checkbox-group
												:options="[
													{ value: 1, text: 'A1' },
													{ value: 2, text: 'A2' },
													{ value: 3, text: 'A3' },
													{ value: 4, text: 'A4' },
													{ value: 5, text: 'A5' },
												]"
												stacked
											></b-form-checkbox-group>
										</b-col>
										<b-col>
											<b-form-checkbox-group
												:options="[
													{ value: 6, text: 'B' },
													{ value: 7, text: 'C' },
													{ value: 8, text: 'D' },
													{ value: 9, text: 'E' },
													{ value: 10, text: 'F' },
												]"
												stacked
											></b-form-checkbox-group>
										</b-col>
									</b-row>
								</b-form-group>
							</b-col>
							<b-col md="4">
								<b-form-group label="Vencimiento" :label-class="form_label_class">
									{{ current.driver.license.expiration_date }}
								</b-form-group>
								<b-form-group label="Teléfono" :label-class="form_label_class">
									<b-input-group prepend="+56">
										<b-input></b-input>
									</b-input-group>
								</b-form-group>
							</b-col>
							<b-col md="5">
								<b-form-group label="Restricciones" :label-class="form_label_class">
									<b-form-checkbox switch size="lg" v-model="form.lentes">
										Lentes ({{ form.lentes == true ? 'sí' : 'no' }})
									</b-form-checkbox>
									<br>
									<b-form-checkbox v-model="form.otras_restricciones">Otras</b-form-checkbox>
									<b-input :disabled="form.otras_restricciones != true"></b-input>
								</b-form-group>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
				<b-card no-body class="mb-3">
					<b-card-header>
						<p class="mb-0 fw-5 text-size-12">Detalles de Solicitud</p>
					</b-card-header>
					<b-card-body class="pb-0">
						<b-row>
							<b-col md="4">
								<b-form-group label="Motivo de la Solicitud" :label-class="form_label_class">
									<b-form-radio-group
										:options="[
											{ value: 1, text: 'Nueva' },
											{ value: 2, text: 'Renovación Licencia Municipal' },
											{ value: 3, text: 'Ampliación de Equipos' },
											{ value: 4, text: 'Renovación Licencia Interna' },
											{ value: 5, text: 'Renovación por Extravío' },
										]"
										stacked
									></b-form-radio-group>
								</b-form-group>
							</b-col>
							<b-col md="8">
								<b-form-group label="Equipos" :label-class="form_label_class">
									<b-row>
										<b-col>
											<b-form-checkbox-group
												v-model="form.equipos"
												:options="[
													{ value: 1, text: 'Ambulancias A/2-A/3' },
													{ value: 2, text: 'Buses A/3-A/1-A/2' },
													{ value: 3, text: 'Camionetas B' },
													{ value: 4, text: 'Carry All A/1-A/2-A/3' },
													{ value: 5, text: 'Camiones sobre 3.500 kgs A/4' },
												]"
												stacked
											></b-form-checkbox-group>
										</b-col>
										<b-col>
											<b-form-checkbox-group
												v-model="form.equipos"
												:options="[
													{ value: 6, text: 'Camiones Pluma/Grúa A / 5-D' },
													{ value: 7, text: 'Minibuses A/2 - A/3' },
													{ value: 8, text: 'Taxis A/2 - A/2 A/3' },
													{ value: 9, text: 'Equipos Licencia D' },
													{ value: 10, text: 'Otro (especificar)' },
												]"
												stacked
											></b-form-checkbox-group>
											<b-input :disabled="!form.equipos.includes(10)" class="mt-1"></b-input>
										</b-col>
									</b-row>
								</b-form-group>
								<b-alert :show="form.equipos.includes(9)" variant="warning" class="py-1 px-2">
									<p class="mb-0">Todos los conductores con licencia D, deberán poseer la certificación respectiva para operar el equipo asignado.</p>
								</b-alert>
							</b-col>
							<b-col md="12">
								<b-form-group label="Área de Operación" description="El sistema solicitará a las áreas seleccionadas pre-aprobar su solicitud antes de que sea revisada por Protección Industrial." :label-class="form_label_class">
									<b-form-checkbox-group
										v-model="form.areas"
										:options="[
											{ value: 1, text: 'Área Administrativa y Caminos Principales' },
											{ value: 2, text: 'Área Planta (SX-EW, Lixiviación, Chancado, Ore Sorting)' },
											{ value: 3, text: 'Área Operaciones Mina' },
											{ value: 4, text: 'Cuesta Michilla' },
										]"
										stacked
									></b-form-checkbox-group>
								</b-form-group>
							</b-col>
							<b-col md="6">
								<b-form-group label="Nombre Responsable EECC">
									<b-form-input></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="6">
								<b-form-group label="Cargo Responsable EECC">
									<b-form-input></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
				
				<b-card no-body class="mb-3" v-if="form.areas.includes(2) || form.areas.includes(3)">
					<b-card-header>
						<p class="mb-0 fw-5 text-size-12">Cursos Teórico - Práctico</p>
					</b-card-header>
					<b-card-body class="pb-0" v-if="form.areas.includes(2)">
						<p class="mb-0 fw-6 text-size-11">Conducción Interior Planta <small>(sólo requisito para área Planta)</small></p>
						<b-row>
							<b-col md="8">
								<b-form-group label="Nombre Instructor">
									<b-form-input></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="4">
								<b-form-group label="Fecha">
									<b-form-datepicker
										:date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
										placeholder="Seleccione fecha"
									></b-form-datepicker>
								</b-form-group>
							</b-col>
						</b-row>
					</b-card-body>
					<hr v-if="form.areas.includes(3) && form.areas.includes(2)">
					<b-card-body class="pb-0" v-if=form.areas.includes(3)>
						<p class="mb-0 fw-6 text-size-11">Conducción Interior Mina <small>(sólo requisito para área Mina)</small></p>
						<b-row>
							<b-col md="8">
								<b-form-group label="Nombre Instructor">
									<b-form-input></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="4">
								<b-form-group label="Fecha">
									<b-form-datepicker
										:date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
										placeholder="Seleccione fecha"
									></b-form-datepicker>
								</b-form-group>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</div>

			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary">Cancelar</b-button>
				<b-button variant="success">SOLICITAR</b-button>
			</template>
		</b-modal>

		<!-- MODAL: PROTECCIÓN INDUSTRIAL / VEHÍCULOS -->
		<b-modal
			id="vehicles-manager"
			title="Vehículos"
			size="lg"
			hide-footer
		>
			<b-table
				:items="current.vehicles"
				:fields="[
					{ key: 'identifier', label: 'RUT', sortable: true },
					{ key: 'type.name', label: 'Tipo', sortable: true },
					{ key: 'company.short_name', label: 'Empresa', sortable: true },
					{ key: 'req_done', label: 'Acreditado', sortable: true },
					{ key: 'qr', label: 'Código QR', sortable: true },
				]"
				hover
				bordered
			>
				<template #cell(qr)="data">
					<div class="text-center" v-if="data.item.req_done == 6">
						<b-button block variant="primary" size="sm">Generar</b-button>
					</div>
					<div class="text-center" v-else>
						<b-button block disabled variant="secondary" size="sm">Generar</b-button>
					</div>
				</template>
				<template #cell(req_done)="data">
					<div class="text-center" v-if="data.value == 6">
						<i class="fas fa-check text-success"></i>
					</div>
					<div class="text-center" v-else>
						<i class="fas fa-exclamation-triangle text-danger"></i>
						{{ 6 - data.value }} Pendiente<span v-if="data.value === 1">s</span>
					</div>
				</template>
			</b-table>
		</b-modal>

		<!-- MODAL: ABASTECIMIENTO / EMPRESA -->
		<b-modal
			id="ayc-company"
			title="Empresa"
			size="lg"
			hide-footer
		>
			<b-list-group>
				<b-list-group-item v-for="(item, index) in reqs.company" :key="index">
					<b-row>
						<b-col sm="auto">
							<i class="fas fa-circle-small mr-2 text-secondary"></i> <span class="text-muted">Pendiente</span>
						</b-col>
						<b-col>
							{{ item.name }}
						</b-col>
						<b-col sm="auto">
							<b-button variant="outline-secondary" class="py-1" size="sm">Cargar Documento</b-button>
						</b-col>
					</b-row>
				</b-list-group-item>
			</b-list-group>
		</b-modal>

		<!-- MODAL: ABASTECIMIENTO / SERVICIOS -->
		<b-modal
			id="ayc-services"
			title="Servicios"
			size="lg"
			hide-footer
		>
			<b-list-group>
				<b-list-group-item v-for="(item, index) in reqs.services" :key="index">
					<b-row>
						<b-col sm="auto">
							<i class="fas fa-circle-small mr-2 text-secondary"></i> <span class="text-muted">Pendiente</span>
						</b-col>
						<b-col>
							{{ item.name }}
						</b-col>
						<b-col sm="auto">
							<b-button variant="outline-secondary" class="py-1" size="sm">Cargar Documento</b-button>
						</b-col>
					</b-row>
				</b-list-group-item>
			</b-list-group>
		</b-modal>

		<!-- MODAL: RRHH / PERSONAS -->
		<b-modal
			id="rrhh-workers"
			title="Personas"
			size="lg"
			hide-footer
		>
		</b-modal>

		<!-- MODAL: DPR / SST -->
		<b-modal
			id="dpr-sst"
			title="Documentación SST"
			size="lg"
			hide-footer
		>
		</b-modal>

		<!-- MODAL: DPR / CARPETA -->
		<b-modal
			id="dpr-archive"
			title="Carpeta de Arranque"
			size="md"
		>
			<b-alert show>
				<p class="mb-0">Aquí se desplegará una descripción personalizada de este requisito, indicando la correcta forma en que cada empresa debe prepararlo antes de cargarlo al sistema.</p>
			</b-alert>
			<b-form-file
				v-model="file1"
				:state="Boolean(file1)"
				placeholder="Seleccione un archivo o arrástrelo aquí..."
				drop-placeholder="Suelte el archivo aquí..."
			></b-form-file>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary">Cancelar</b-button>
				<b-button variant="success" :disabled="Boolean(file1) == false">CARGAR</b-button>
			</template>
		</b-modal>

		<!-- MODAL: DPR / REUNION -->
		<b-modal
			id="dpr-meeting"
			title="Reunión de Arranque"
			size="md"
		>
			<b-alert show>
				<p class="mb-0">Aquí se desplegará una descripción personalizada de este requisito, indicando la correcta forma en que cada empresa debe prepararlo antes de cargarlo al sistema.</p>
			</b-alert>
			<b-form-file
				v-model="file1"
				:state="Boolean(file2)"
				placeholder="Seleccione un archivo o arrástrelo aquí..."
				drop-placeholder="Suelte el archivo aquí..."
			></b-form-file>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary">Cancelar</b-button>
				<b-button variant="success" :disabled="Boolean(file2) == false">CARGAR</b-button>
			</template>
		</b-modal>


	</div>
</template>

<script>
export default {
	name: 'HomeInternal',
	components: {
	},
	data() {
		return {
			loading: true,
			items: [],
			file1: null,
			file2: null,
			vehicle_type: null,
			form: {
				equipos: [],
				motivo: null,
				telefono: '',
				lentes: false,
				otras_restricciones: false,
				areas: []
			},
			current: {
				item: [],
				drivers: [],
				vehicles: [],
				driver: [],
			},
			reqs: {
				company: [
					{ name: 'Formulario 22: Impuestos a la Renta' },
					{ name: 'Formulario 29: Declaraciones Mensuales de Impuestos' },
					{ name: 'Acreditación de Cuentas Corrientes' },
					{ name: 'Copia de Constitución de Empresa y Modificaciones Sociales' },
					{ name: 'Certificado de Deuda de la Tesorería General de la República' },
					{ name: 'Declaración Jurada Ley 20.393' },
					{ name: 'RUT Sociedad' },
					{ name: 'Certificado de Vigencia de Sociedad' },
					{ name: 'Carpeta Tributaria Electrónica' },
					{ name: 'Inicio de Actividades ante el SII' },
				],
				services: [
					{ name: 'xxxxxxxxxxxxxx' },
					{ name: 'xxxxxxxxxxxxxx' },
					{ name: 'xxxxxxxxxxxxxx' },
					{ name: 'xxxxxxxxxxxxxx' },
					{ name: 'xxxxxxxxxxxxxx' },
					{ name: 'xxxxxxxxxxxxxx' },
					{ name: 'xxxxxxxxxxxxxx' },
					{ name: 'xxxxxxxxxxxxxx' },
				],
			},
			form_label_class: 'fw-5 text-uppercase text-size-08 pb-0 mb-0 text-dark',
		}
	},
	created() {
		this.load()
	},
	mounted() {
	},
	computed: {
	},
	methods: {
		openSearch() {
			this.$bvModal.show('new-search')
		},
		newCompany() {
			this.$bvModal.show('new-company')
		},
		openDrivers(item) {
			this.current.item = item
			this.current.drivers = item.stage4.drivers
			this.$bvModal.show('drivers-manager')
		},
		openDriversLicense(item) {
			this.current.driver = item
			this.$bvModal.show('drivers-license-generator')
		},
		openVehicles(item) {
			this.current.item = item
			this.current.vehicles = item.stage4.vehicles
			this.$bvModal.show('vehicles-manager')
		},
		newService() {
			this.$bvModal.show('new-service')
		},
		newWorker() {
			this.$bvModal.show('new-worker')
		},
		newVehicle() {
			this.$bvModal.show('new-vehicle')
		},
		aycCompany() {
			this.$bvModal.show('ayc-company')
		},
		aycServices() {
			this.$bvModal.show('ayc-services')
		},
		rrhhWorkers() {
			this.$bvModal.show('rrhh-workers')
		},
		dprMeeting() {
			this.$bvModal.show('dpr-meeting')
		},
		dprArchive() {
			this.$bvModal.show('dpr-archive')
		},
		dprSST() {
			this.$bvModal.show('dpr-sst')
		},
		reload() {
			this.load()
		},
		load() {
			this.loading = true
			this.$api.get('demo/home_internal')
			.then(response => {
				this.loading = false
				this.items = response.data
			})
			.catch(error => {
				this.loading = false
				console.log(error)
			})
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		toggleCollapse(index) {
			this.$root.$emit('bv::toggle::collapse', 'collapse'+index)
		},
		goTo(route_name) {
			if (route_name == this.$route.name) return false;
			this.$router.push({ name: route_name })
		},
	}
}
</script>

<style>
</style>
