<template>
	<div>
		
		<b-container fluid>

			<p class="text-size-2 mb-3 fw-7">Recursos Humanos</p>
			
			<b-card no-body class="mb-5">
				
				<b-table
					:items="test"
					:fields="[
						{ key: 'person.identifier', label: 'RUT', sortable: true },
						{ key: 'person.primary_last_name', label: '1er Apellido', sortable: true },
						{ key: 'person.second_last_name', label: '2do Apellido', sortable: true },
						{ key: 'person.names', label: 'Nombres', sortable: true },
						{ key: 'company.short_name', label: 'Empresa', sortable: true },
						{ key: 'area', label: 'Área', sortable: true },
						{ key: 'position', label: 'Cargo', sortable: true },
						{ key: 'requirements', label: 'Acreditación', sortable: true },
						{ key: 'shift.name', label: 'Turno', sortable: true },
						{ key: 'on_site', label: 'En Faena', sortable: true },
					]"
					hover
					small
					class="text-size-09"
				>
					<template #thead-top="">
						<b-tr>
							<b-th variant="secondary">
								<b-input></b-input>
							</b-th>
							<b-th variant="secondary">
								<b-input></b-input>
							</b-th>
							<b-th variant="secondary">
								<b-input></b-input>
							</b-th>
							<b-th variant="secondary">
								<b-input></b-input>
							</b-th>
							<b-th variant="secondary">
								<b-select></b-select>
							</b-th>
							<b-th variant="secondary">
								<b-input></b-input>
							</b-th>
							<b-th variant="secondary">
								<b-input></b-input>
							</b-th>
							<b-th variant="secondary">
								<b-select></b-select>
							</b-th>
							<b-th variant="secondary">
								<b-select></b-select>
							</b-th>
							<b-th variant="secondary">
								<b-select></b-select>
							</b-th>
						</b-tr>
					</template>
					<template #cell(person.identifier)="data">
						{{ parseIdentifier(data.value) }}
					</template>
					<template #cell(requirements)="data">
						<div class="text-left">
							<div v-if="data.item.company_id == '40f4a8bc-aa65-48ab-afa7-0b7dc42e4481'"></div>
							<div v-else-if="data.item.req_done == 9">
								<i class="fas fa-check text-success"></i>
							</div>
							<div v-else>
								<i class="fas fa-exclamation-triangle text-danger"></i> {{ 9 - data.item.req_done }}
							</div>
						</div>
					</template>
					<template #cell(service)="data">
						<div class="text-left">
							<div>
								<div v-if="data.value.status == -1">
									<i class="fas fa-exclamation-triangle text-danger"></i>
								</div>
								<div v-else-if="data.value.status == 1">
									<i class="fas fa-exclamation-triangle text-danger"></i>
								</div>
								<div v-else-if="data.value.status == 2">
									<i class="fas fa-check text-success"></i>
								</div>
							</div>
						</div>
					</template>
					<template #cell(on_site)="data">
						<div class="text-left">
							<div>
								<div v-if="data.value.enter">
									<i class="fas fa-circle text-primary mr-1"></i>
									<span class="">{{ data.value.timestamp }}</span>
								</div>
								<div v-else>
									<i class="far fa-circle text-muted mr-1"></i>
									<span class="text-muted">{{ data.value.timestamp }}</span>
								</div>
							</div>
						</div>
					</template>
				</b-table>

			</b-card>
			
		</b-container>


	</div>
</template>

<script>
export default {
	name: 'Vista2',
	components: {
	},
	data() {
		return {
			loading: true,
			test: null,
		}
	},
	created() {
		this.load()
	},
	mounted() {
	},
	computed: {
	},
	methods: {
		reload() {
			this.load()
		},
		load() {
			this.loading = true
			this.$api.get('vista2')
			.then(response => {
				this.loading = false
				console.log(response.data)
				this.test = response.data
			})
			.catch(error => {
				this.loading = false
				console.log(error)
			})
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
	}
}
</script>

<style>
</style>
