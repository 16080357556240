<template>
	<div>

		<b-container>

			<b-list-group class="mb-4" v-if="0">
				<b-list-group-item variant="secondary" class="py-2 px-3">
					<span class="text-size-13">
						<strong>Acreditación Global</strong>
					</span>
				</b-list-group-item>
				<b-list-group-item>
					<b-row>
						<b-col>
							<b-list-group>
								<b-list-group-item class="px-3 py-1">
									<strong>Empresas</strong>
								</b-list-group-item>
								<b-list-group-item class="px-3 py-2">
									<b-row>
										<b-col>
											Contratistas
										</b-col>
										<b-col class="text-right" sm="auto">
											89%
										</b-col>
									</b-row>
									<b-progress height="6px" class="mt-1 mb-2">
										<b-progress-bar :value="89" variant="success"></b-progress-bar>
										<b-progress-bar :value="11" variant="danger"></b-progress-bar>
									</b-progress>
								</b-list-group-item>
								<b-list-group-item class="px-3 py-2">
									<b-row>
										<b-col>
											Subcontratistas
										</b-col>
										<b-col class="text-right" sm="auto">
											46%
										</b-col>
									</b-row>
									<b-progress height="6px" class="mt-1 mb-2">
										<b-progress-bar :value="46" variant="success"></b-progress-bar>
										<b-progress-bar :value="54" variant="danger"></b-progress-bar>
									</b-progress>
								</b-list-group-item>
							</b-list-group>
						</b-col>
						<b-col>
							<b-list-group>
								<b-list-group-item class="px-3 py-1">
									<strong>Servicios</strong>
								</b-list-group-item>
								<b-list-group-item class="px-3 py-2">
									<b-row>
										<b-col>
											Órdenes de Servicio
										</b-col>
										<b-col class="text-right" sm="auto">
											89%
										</b-col>
									</b-row>
									<b-progress height="6px" class="mt-1 mb-2">
										<b-progress-bar :value="89" variant="success"></b-progress-bar>
										<b-progress-bar :value="11" variant="danger"></b-progress-bar>
									</b-progress>
								</b-list-group-item>
								<b-list-group-item class="px-3 py-2">
									<b-row>
										<b-col>
											Contratos
										</b-col>
										<b-col class="text-right" sm="auto">
											46%
										</b-col>
									</b-row>
									<b-progress height="6px" class="mt-1 mb-2">
										<b-progress-bar :value="46" variant="success"></b-progress-bar>
										<b-progress-bar :value="54" variant="danger"></b-progress-bar>
									</b-progress>
								</b-list-group-item>
								<b-list-group-item class="px-3 py-2">
									<b-row>
										<b-col>
											Subcontratos
										</b-col>
										<b-col class="text-right" sm="auto">
											46%
										</b-col>
									</b-row>
									<b-progress height="6px" class="mt-1 mb-2">
										<b-progress-bar :value="46" variant="success"></b-progress-bar>
										<b-progress-bar :value="54" variant="danger"></b-progress-bar>
									</b-progress>
								</b-list-group-item>
								<b-list-group-item class="px-3 py-2">
									<b-row>
										<b-col>
											Garantías
										</b-col>
										<b-col class="text-right" sm="auto">
											46%
										</b-col>
									</b-row>
									<b-progress height="6px" class="mt-1 mb-2">
										<b-progress-bar :value="46" variant="success"></b-progress-bar>
										<b-progress-bar :value="54" variant="danger"></b-progress-bar>
									</b-progress>
								</b-list-group-item>
							</b-list-group>
						</b-col>
						<b-col>
							<b-list-group>
								<b-list-group-item class="px-3 py-1">
									<strong>Trabajadores</strong>
								</b-list-group-item>
								<b-list-group-item class="px-3 py-2">
									<b-row>
										<b-col>
											Contratistas
										</b-col>
										<b-col class="text-right" sm="auto">
											89%
										</b-col>
									</b-row>
									<b-progress height="6px" class="mt-1 mb-2">
										<b-progress-bar :value="89" variant="success"></b-progress-bar>
										<b-progress-bar :value="11" variant="danger"></b-progress-bar>
									</b-progress>
								</b-list-group-item>
								<b-list-group-item class="px-3 py-2">
									<b-row>
										<b-col>
											Subcontratistas
										</b-col>
										<b-col class="text-right" sm="auto">
											46%
										</b-col>
									</b-row>
									<b-progress height="6px" class="mt-1 mb-2">
										<b-progress-bar :value="46" variant="success"></b-progress-bar>
										<b-progress-bar :value="54" variant="danger"></b-progress-bar>
									</b-progress>
								</b-list-group-item>
							</b-list-group>
						</b-col>
						<b-col>
							<b-list-group>
								<b-list-group-item class="px-3 py-1">
									<strong>Vehículos</strong>
								</b-list-group-item>
								<b-list-group-item class="px-3 py-2">
									<b-row>
										<b-col>
											Contratistas
										</b-col>
										<b-col class="text-right" sm="auto">
											89%
										</b-col>
									</b-row>
									<b-progress height="6px" class="mt-1 mb-2">
										<b-progress-bar :value="89" variant="success"></b-progress-bar>
										<b-progress-bar :value="11" variant="danger"></b-progress-bar>
									</b-progress>
								</b-list-group-item>
								<b-list-group-item class="px-3 py-2">
									<b-row>
										<b-col>
											Subcontratistas
										</b-col>
										<b-col class="text-right" sm="auto">
											46%
										</b-col>
									</b-row>
									<b-progress height="6px" class="mt-1 mb-2">
										<b-progress-bar :value="46" variant="success"></b-progress-bar>
										<b-progress-bar :value="54" variant="danger"></b-progress-bar>
									</b-progress>
								</b-list-group-item>
							</b-list-group>
						</b-col>
					</b-row>
				</b-list-group-item>
			</b-list-group>

			<p class="fw-bold text-size-2 mb-1">
				<span>Acreditación</span>
				<b-overlay
					:show="loading"
					rounded
					opacity="0.6"
					class="d-inline-block mb-3"
					v-if="0"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
					<b-button variant="light" @click="reload()" v-b-tooltip.hover.bottomright title="Actualizar listado de empresas">
						<i class="fas fa-redo"></i>
					</b-button>
				</b-overlay>
			</p>
			<p class="text-size-14 mb-3">Conozca el avance específico de la acreditación de todas las empresas.</p>


			<b-row>
				<b-col sm="auto">
					<p class="fw-bold text-size-11 mb-2">Acciones Rápidas</p>
					<b-row class="mb-4">
						<b-col sm="auto" class="pr-0">
							<b-button variant="outline-dark">
								<i class="fas fa-search mr-1"></i>
								<span>Buscar</span>
							</b-button>
						</b-col>
						<b-col sm="auto">
							<b-button variant="outline-dark">
								<i class="fas fa-rotate-right mr-1"></i>
								<span>Recargar</span>
							</b-button>
						</b-col>
					</b-row>
				</b-col>
				<b-col sm="auto">
					<p class="fw-bold text-size-11 mb-2">Nuevos Registros</p>
					<b-row class="mb-4">
						<b-col sm="auto" class="pr-0">
							<b-button variant="outline-dark">
								<i class="fas fa-plus mr-1"></i>
								<span>Empresa</span>
							</b-button>
						</b-col>
						<b-col sm="auto" class="pr-0">
							<b-button variant="outline-dark">
								<i class="fas fa-plus mr-1"></i>
								<span>Servicio</span>
							</b-button>
						</b-col>
						<b-col sm="auto" class="pr-0">
							<b-button variant="outline-dark">
								<i class="fas fa-plus mr-1"></i>
								<span>Persona</span>
							</b-button>
						</b-col>
						<b-col sm="auto" class="pr-0">
							<b-button variant="outline-dark">
								<i class="fas fa-plus mr-1"></i>
								<span>Vehículo</span>
							</b-button>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			

			<p class="fw-bold text-size-14 mb-2">{{ test.length }} Empresas Vigentes</p>
			<b-list-group class="mb-5">
				<b-list-group-item v-for="(item, index) in test" :key="index" button @click="toggleCollapse(index)" class="px-3 py-3">
					<b-row>
						<b-col sm="4">
							<p class="mb-0 nowrap text-size-12">
								<i class="fas fa-check-circle text-success" v-if="item.req_done == 17"></i>
								<i class="fas fa-times-circle text-danger" v-else></i>
								<span class="ml-2 text-uppercase" style="font-weight: 500">{{ item.short_name }}</span>
							</p>
						</b-col>
						<b-col class="text-right">
							<small class="text-uppercase text-muted" style="font-weight: 400">{{ item.full_name }}</small>
						</b-col>
					</b-row>
					<b-collapse :id="`collapse${index}`" class="pl-0 mt-0">
						<b-row class="mb-3">
							<b-col sm="auto" class="pr-0">
								<p class="mb-0 text-size-10 text-muted">
									<i class="far fa-fw fa-building mr-1"></i>
									<span>{{ parseIdentifier(item.identifier) }}</span>
								</p>
							</b-col>
							<b-col sm="auto" class="pr-0">
								<p class="mb-0 text-lowercase text-size-10 text-muted">
									<i class="far fa-fw fa-envelope mr-1"></i>
									<span>{{ item.email }}</span>
								</p>
							</b-col>
							<b-col sm="auto" class="pr-0">
								<p class="mb-0 text-size-10 text-muted">
									<i class="far fa-fw fa-phone mr-1"></i>
									<span>{{ item.phone }}</span>
								</p>
							</b-col>
							<b-col sm="auto">
								<p class="mb-0 text-size-10 text-muted text-uppercase">
									<i class="far fa-fw fa-location-dot mr-1"></i>
									<span>{{ item.address }}, </span>
									<span v-if="item.commune != null && item.commune.length > 0">{{ item.commune }}</span>
									<span v-else-if="item.commune_id != null && item.commune_rel != null && item.commune_rel.name">{{ item.commune_rel.name }}</span>
									<span v-else>Comuna No Informada</span>
								</p>
							</b-col>
							<b-col class="text-right">
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<b-card header-class="px-3 py-2" header-bg-variant="success" header-text-variant="white" body-class="px-3 py-3">
									<template #header>
										<i class="fas fa-check-circle mr-1"></i>
										<span>Abastecimiento y Contratos</span>
									</template>
									<b-button block variant="outline-success" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-check mr-1"></i>
										<span>Empresa Acreditada</span>
									</b-button>
									<b-button block variant="outline-success" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-check mr-1"></i>
										<span>{{ item.services.length }} Servicios Acreditados</span>
									</b-button>
								</b-card>
							</b-col>
							<b-col sm="auto" class="p-0">
								<i class="fas fa-chevron-right text-size-13 mt-3 text-success"></i>
							</b-col>
							<b-col>
								<b-card header-class="px-3 py-2" header-bg-variant="success" header-text-variant="white" body-class="px-3 py-3">
									<template #header>
										<i class="fas fa-check-circle mr-1"></i>
										<span>Recursos Humanos</span>
									</template>
									<b-button block variant="outline-success" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-check mr-1"></i>
										<span>43 Personas Acreditadas</span>
									</b-button>
									<b-button block variant="outline-success" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-check mr-1"></i>
										<span>43 Personas en Nómina</span>
									</b-button>
								</b-card>
							</b-col>
							<b-col sm="auto" class="p-0">
								<i class="fas fa-chevron-right text-size-13 mt-3 text-success"></i>
							</b-col>
							<b-col>
								<b-card header-class="px-3 py-2" header-bg-variant="success" header-text-variant="white" body-class="px-3 py-3">
									<template #header>
										<i class="fas fa-check-circle mr-1"></i>
										<span>Prevención de Riesgos</span>
									</template>
									<b-button block variant="outline-success" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-check mr-1"></i>
										<span>Documentación SST</span>
									</b-button>
									<b-button block variant="outline-success" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-check mr-1"></i>
										<span>Carpeta de Arranque</span>
									</b-button>
									<b-button block variant="outline-success" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-check mr-1"></i>
										<span>Reunión de Arranque</span>
									</b-button>
								</b-card>
							</b-col>
							<b-col>
								<b-card header-class="px-3 py-2" header-bg-variant="success" header-text-variant="white" body-class="px-3 py-3">
									<template #header>
										<i class="fas fa-check-circle mr-1"></i>
										<span>Protección Industrial</span>
									</template>
									<b-button block variant="outline-success" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-check mr-1"></i>
										<span>8 Licencias Internas</span>
									</b-button>
									<b-button block variant="outline-success" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-check mr-1"></i>
										<span>4 Vehículos Motorizados</span>
									</b-button>
								</b-card>
							</b-col>
						</b-row>

						<b-row v-if="index == 0" class="mt-4">
							<b-col>
								<b-card header-class="px-3 py-2" header-bg-variant="danger" header-text-variant="white" body-class="px-3 py-3">
									<template #header>
										<i class="fas fa-exclamation-triangle mr-1"></i>
										<span>Abastecimiento y Contratos</span>
									</template>
									<b-button block variant="outline-danger" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
										<span>Empresa No Acreditada</span>
									</b-button>
									<b-button block variant="outline-danger" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
										<span>{{ item.services.length }} Servicios No Acreditados</span>
									</b-button>
								</b-card>
							</b-col>
							<b-col sm="auto" class="p-0">
								<i class="fas fa-chevron-right text-size-13 mt-3 text-danger"></i>
							</b-col>
							<b-col>
								<b-card header-class="px-3 py-2" header-bg-variant="danger" header-text-variant="white" body-class="px-3 py-3">
									<template #header>
										<i class="fas fa-exclamation-triangle mr-1"></i>
										<span>Recursos Humanos</span>
									</template>
									<b-button block variant="outline-danger" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
										<span>43 Personas No Acreditadas</span>
									</b-button>
									<b-button block variant="outline-danger" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
										<span>43 Personas Sin  Nómina</span>
									</b-button>
								</b-card>
							</b-col>
							<b-col sm="auto" class="p-0">
								<i class="fas fa-chevron-right text-size-13 mt-3 text-danger"></i>
							</b-col>
							<b-col>
								<b-card header-class="px-3 py-2" header-bg-variant="danger" header-text-variant="white" body-class="px-3 py-3">
									<template #header>
										<i class="fas fa-exclamation-triangle mr-1"></i>
										<span>Prevención de Riesgos</span>
									</template>
									<b-button block variant="outline-danger" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
										<span>Documentación SST</span>
									</b-button>
									<b-button block variant="outline-danger" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
										<span>Carpeta de Arranque</span>
									</b-button>
									<b-button block variant="outline-danger" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
										<span>Reunión de Arranque</span>
									</b-button>
								</b-card>
							</b-col>
							<b-col>
								<b-card header-class="px-3 py-2" header-bg-variant="danger" header-text-variant="white" body-class="px-3 py-3">
									<template #header>
										<i class="fas fa-exclamation-triangle mr-1"></i>
										<span>Protección Industrial</span>
									</template>
									<b-button block variant="outline-danger" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
										<span>4 Licencias Pendientes</span>
									</b-button>
									<b-button block variant="outline-danger" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-exclamation-triangle mr-1"></i>
										<span>2 Vehículos No Acreditados</span>
									</b-button>
								</b-card>
							</b-col>
						</b-row>

						<b-row v-if="index == 0" class="mt-4">
							<b-col>
								<b-card header-class="px-3 py-2" header-bg-variant="secondary" header-text-variant="white" body-class="px-3 py-3">
									<template #header>
										<i class="fas fa-folder-open mr-1"></i>
										<span>Abastecimiento y Contratos</span>
									</template>
									<b-button block variant="outline-secondary" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-upload mr-1"></i>
										<span>Acreditar Empresa</span>
									</b-button>
									<b-button block variant="outline-secondary" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-upload mr-1"></i>
										<span>Acreditar Servicios</span>
									</b-button>
								</b-card>
							</b-col>
							<b-col sm="auto" class="p-0">
								<i class="fas fa-chevron-right text-size-13 mt-3 text-secondary"></i>
							</b-col>
							<b-col>
								<b-card header-class="px-3 py-2" header-bg-variant="secondary" header-text-variant="white" body-class="px-3 py-3">
									<template #header>
										<i class="fas fa-folder-open mr-1"></i>
										<span>Recursos Humanos</span>
									</template>
									<b-button block variant="outline-secondary" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-upload mr-1"></i>
										<span>Acreditar Personas</span>
									</b-button>
									<b-button block variant="outline-secondary" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-upload mr-1"></i>
										<span>Activar Personas</span>
									</b-button>
								</b-card>
							</b-col>
							<b-col sm="auto" class="p-0">
								<i class="fas fa-chevron-right text-size-13 mt-3 text-secondary"></i>
							</b-col>
							<b-col>
								<b-card header-class="px-3 py-2" header-bg-variant="secondary" header-text-variant="white" body-class="px-3 py-3">
									<template #header>
										<i class="fas fa-folder-open mr-1"></i>
										<span>Prevención de Riesgos</span>
									</template>
									<b-button block variant="outline-secondary" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-upload mr-1"></i>
										<span>Cargar Documentación SST</span>
									</b-button>
									<b-button block variant="outline-secondary" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-upload mr-1"></i>
										<span>Cargar Carpeta de Arranque</span>
									</b-button>
									<b-button block variant="outline-secondary" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-upload mr-1"></i>
										<span>Cargar Reunión de Arranque</span>
									</b-button>
								</b-card>
							</b-col>
							<b-col>
								<b-card header-class="px-3 py-2" header-bg-variant="secondary" header-text-variant="white" body-class="px-3 py-3">
									<template #header>
										<i class="fas fa-folder-open mr-1"></i>
										<span>Protección Industrial</span>
									</template>
									<b-button block variant="outline-secondary" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-upload mr-1"></i>
										<span>Acreditar Conductores</span>
									</b-button>
									<b-button block variant="outline-secondary" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-upload mr-1"></i>
										<span>Acreditar Vehículos</span>
									</b-button>
								</b-card>
							</b-col>
						</b-row>

						<b-row v-if="index == 0" class="mt-4">
							<b-col>
								<b-card header-class="px-3 py-2" header-bg-variant="light" header-text-variant="secondary" body-class="px-3 py-3">
									<template #header>
										<span>Abastecimiento y Contratos</span>
									</template>
									<b-alert show variant="warning" class="px-2 py-1">
										<p class="mb-0 text-size-09">Documentos de esta etapa son los primeros en ser revisados.</p>
									</b-alert>
									<b-button block variant="outline-info" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-info-circle mr-1"></i>
										<span>Información de Requisitos</span>
									</b-button>
								</b-card>
							</b-col>
							<b-col sm="auto" class="p-0">
								<i class="fas fa-chevron-right text-size-13 mt-3 text-muted"></i>
							</b-col>
							<b-col>
								<b-card header-class="px-3 py-2" header-bg-variant="light" header-text-variant="secondary" body-class="px-3 py-3">
									<template #header>
										<span>Recursos Humanos</span>
									</template>
									<b-alert show variant="warning" class="px-2 py-1">
										<p class="mb-0 text-size-09">Documentos de esta etapa no son revisados hasta completar Abastecimiento y Contratos.</p>
									</b-alert>
									<b-button block variant="outline-info" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-info-circle mr-1"></i>
										<span>Información de Requisitos</span>
									</b-button>
								</b-card>
							</b-col>
							<b-col sm="auto" class="p-0">
								<i class="fas fa-chevron-right text-size-13 mt-3 text-muted"></i>
							</b-col>
							<b-col>
								<b-card header-class="px-3 py-2" header-bg-variant="light" header-text-variant="secondary" body-class="px-3 py-3">
									<template #header>
										<span>Prevención de Riesgos</span>
									</template>
									<b-alert show variant="warning" class="px-2 py-1">
										<p class="mb-0 text-size-09">Documentos de esta etapa no son revisados hasta completar Recursos Humanos.</p>
									</b-alert>
									<b-button block variant="outline-info" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-info-circle mr-1"></i>
										<span>Información de Requisitos</span>
									</b-button>
								</b-card>
							</b-col>
							<b-col>
								<b-card header-class="px-3 py-2" header-bg-variant="light" header-text-variant="secondary" body-class="px-3 py-3">
									<template #header>
										<span>Protección Industrial</span>
									</template>
									<b-alert show variant="warning" class="px-2 py-1">
										<p class="mb-0 text-size-09">Documentos de esta etapa no son revisados hasta completar Prevención de Riesgos.</p>
									</b-alert>
									<b-button block variant="outline-info" class="text-left py-2" size="sm">
										<i class="fas fa-fw fa-info-circle mr-1"></i>
										<span>Información de Requisitos</span>
									</b-button>
								</b-card>
							</b-col>
						</b-row>

					</b-collapse>
				</b-list-group-item>
			</b-list-group>

		</b-container>

	</div>
</template>

<script>
export default {
	name: 'Vista3',
	components: {
	},
	data() {
		return {
			loading: true,
			test: null,
		}
	},
	created() {
		this.load()
	},
	mounted() {
	},
	computed: {
	},
	methods: {
		reload() {
			this.load()
		},
		load() {
			this.loading = true
			this.$api.get('vista5')
			.then(response => {
				this.loading = false
				console.log(response.data)
				this.test = response.data
			})
			.catch(error => {
				this.loading = false
				console.log(error)
			})
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		toggleCollapse(index) {
			this.$root.$emit('bv::toggle::collapse', 'collapse'+index)
		}
	}
}
</script>

<style>
</style>
