<template>
	<div>
		
		<b-container>
			<p class="text-size-2 mb-3 fw-7">Protección Industrial</p>
			
			<b-card no-body class="mb-5">
				
				<b-table
					:items="test"
					:fields="[
						{ key: 'company', label: 'Empresa', sortable: false },
						{ key: 'services', label: 'Servicios', sortable: false },
					]"
					hover
				>
					<template #cell(company)="data">
						<div>
							<p class="mb-0 nowrap text-size-12">
								<span class="mr-1" style="font-weight: 500">{{ data.item.short_name }}</span>
								<i class="fas fa-check-circle text-success" v-if="data.item.req_done == 17"></i>
								<i class="fas fa-pause-circle text-secondary" v-else></i>
							</p>
							<p class="mb-1 text-size-11 nowrap">{{ parseIdentifier(data.item.identifier) }}</p>
							<p class="mb-0 text-lowercase text-size-09 text-muted">
								<i class="far fa-envelope mr-1"></i>
								<span>{{ data.item.email }}</span>
							</p>
							<p class="mb-0 text-lowercase text-size-09 text-muted">
								<i class="far fa-phone mr-1"></i>
								<span>{{ data.item.phone }}</span>
							</p>
						</div>
					</template>
					<template #cell(services)="data">
						<b-alert :show="data.item.req_done < 17" class="px-3 py-3" variant="danger">
							<p class="mb-0 text-size-11">
								<span>No se puede interactuar con estos servicios porque aún existen revisiones pendientes del Departamento de {{ data.index % 3 == 1 ? 'Abastecimiento y Contratos' : 'Recursos Humanos' }}.</span>
							</p>
						</b-alert>
						<b-list-group>
							<b-list-group-item v-for="(item, index) in data.value" :key="index" class="px-2 py-2" :button="data.item.req_done < 17 ? false : true">
								<b-row>
									<b-col>
										<p class="mb-0">
											<span v-if="data.item.req_done < 17" class="text-secondary">
												<strong>{{ item.cod }}</strong>
											</span>
											<span v-else-if="index % 3 != 1" class="text-success">
												<strong>{{ item.cod }}</strong>
											</span>
											<span v-else-if="index % 3 == 1" class="text-danger">
												<strong>{{ item.cod }}</strong>
											</span>
											<b-badge class="text-size-09 ml-2 text-uppercase" variant="light">
												{{ item.type == 1 ? 'Orden de Servicio' : item.type == 2 ? 'Contrato' : item.type == 3 ? 'Garantía' : 'Subcontrato' }}
											</b-badge>
										</p>
										<p class="mb-0 text-size-09 text-uppercase">{{ item.comments }}</p>
										<p class="mb-0 text-size-08 text-uppercase text-muted">
											<i class="far fa-user mr-1"></i>
											<span>{{ item.administrator.person.names.split(' ')[0] }} {{ item.administrator.person.primary_last_name }} / {{ item.administrator.position }}</span>
										</p>
									</b-col>
									<b-col class="nowrap" sm="auto">
										<p class="mb-1 text-right text-size-09">
											<span>{{ item.start_date }}</span>
											<i class="fas fa-chevron-right text-size-07 mx-1"></i>
											<span>{{ item.real_end_date }}</span>
										</p>
										<b-row>
											<b-col>
												<b-button :disabled="data.item.req_done < 17" :variant="data.item.req_done < 17 ? 'outline-secondary': index % 3 != 1 ? 'outline-success' : 'danger'" size="sm" block class="py-2">
													<i class="fas fa-fw fa-pause mr-1" v-if="data.item.req_done < 17"></i>
													<i class="fas fa-fw fa-check mr-1" v-else-if="index % 3 != 1"></i>
													<i class="fas fa-fw fa-times mr-1" v-else></i>
													<span>Licencias</span>
												</b-button>
											</b-col>
											<b-col>
												<b-button :disabled="data.item.req_done < 17" :variant="data.item.req_done < 17 ? 'outline-secondary': index % 3 != 1 ? 'outline-success' : 'danger'" size="sm" block class="py-2">
													<i class="fas fa-fw fa-pause mr-1" v-if="data.item.req_done < 17"></i>
													<i class="fas fa-fw fa-check mr-1" v-else-if="index % 3 != 1"></i>
													<i class="fas fa-fw fa-times mr-1" v-else></i>
													<span>Vehículos</span>
												</b-button>
											</b-col>
											<b-col>
												<b-button :disabled="data.item.req_done < 17" :variant="data.item.req_done < 17 ? 'outline-secondary': index % 3 != 1 ? 'outline-success' : 'danger'" size="sm" block class="py-2">
													<i class="fas fa-fw fa-pause mr-1" v-if="data.item.req_done < 17"></i>
													<i class="fas fa-fw fa-check mr-1" v-else-if="index % 3 != 1"></i>
													<i class="fas fa-fw fa-times mr-1" v-else></i>
													<span>Controles</span>
												</b-button>
											</b-col>
										</b-row>
									</b-col>
								</b-row>
							</b-list-group-item>
						</b-list-group>
					</template>
				</b-table>

			</b-card>
			
		</b-container>


	</div>
</template>

<script>
export default {
	name: 'Vista3',
	components: {
	},
	data() {
		return {
			loading: true,
			test: null,
		}
	},
	created() {
		this.load()
	},
	mounted() {
	},
	computed: {
	},
	methods: {
		reload() {
			this.load()
		},
		load() {
			this.loading = true
			this.$api.get('vista3')
			.then(response => {
				this.loading = false
				console.log(response.data)
				this.test = response.data
			})
			.catch(error => {
				this.loading = false
				console.log(error)
			})
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
	}
}
</script>

<style>
</style>
