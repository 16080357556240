import Vue from 'vue'
import VueRouter from 'vue-router'

import Account from '@/views/Account.vue'
import Attendance from '@/views/Attendance.vue'
import Contractor from '@/views/Contractor.vue'
import ControlFixer from '@/views/ControlFixer.vue'
import Contractors from '@/views/Contractors.vue'
import DailyReport from '@/views/DailyReport.vue'
import Expirations from '@/views/Expirations.vue'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue'
import Monitor from '@/views/Monitor.vue'
import OnSite from '@/views/OnSite.vue'
import Passes from '@/views/Passes.vue'
import CargoPasses from '@/views/CargoPasses.vue'
import CorporatePasses from '@/views/CorporatePasses.vue'
import PassToken from '@/views/PassToken.vue'
import CargoPassToken from '@/views/CargoPassToken.vue'
import CorporatePassToken from '@/views/CorporatePassToken.vue'
import PublicDailyReport from '@/views/PublicDailyReport.vue'
import Recover from '@/views/Recover.vue'
import Reports from '@/views/Reports.vue'
import RandomTesting from '@/views/RandomTesting.vue'
import RequirementsReport from '@/views/RequirementsReport.vue'
import Reset from '@/views/Reset.vue'
import Services from '@/views/Services.vue'
import Shifts from '@/views/Shifts.vue'
import Stats from '@/views/Stats.vue'
import StatsContractor from '@/views/StatsContractor.vue'
import StatsContractWorker from '@/views/StatsContractWorker.vue'
import StatsContractVehicle from '@/views/StatsContractVehicle.vue'
import StatsSubcontractor from '@/views/StatsSubcontractor.vue'
import StatsSubcontractWorker from '@/views/StatsSubcontractWorker.vue'
import StatsSubcontractVehicle from '@/views/StatsSubcontractVehicle.vue'
import StatsService from '@/views/StatsService.vue'
import StatsContract from '@/views/StatsContract.vue'
import StatsWarranty from '@/views/StatsWarranty.vue'
import StatsSubcontract from '@/views/StatsSubcontract.vue'
import StatsControl from '@/views/StatsControl.vue'
import StatsAction from '@/views/StatsAction.vue'
import Subcontractors from '@/views/Subcontractors.vue'
import Support from '@/views/Support.vue'
import System from '@/views/System.vue'
import UserLogs from '@/views/UserLogs.vue'
import Users from '@/views/Users.vue'
import Vehicles from '@/views/Vehicles.vue'
import Workers from '@/views/Workers.vue'
import Grids from '@/views/Grids.vue'
import Approvers from '@/views/Approvers.vue'
import Queues from '@/views/Queues.vue'
import Accreditation from '@/views/Accreditation.vue'
import RequirementsManager from '@/views/RequirementsManager.vue'
import GlobalParameters from '@/views/GlobalParameters.vue'
import UpgradeDCS from '@/views/UpgradeDCS.vue'
import Vista1 from '@/views/Vista1.vue'
import Vista2 from '@/views/Vista2.vue'
import Vista3 from '@/views/Vista3.vue'
import Vista4 from '@/views/Vista4.vue'
import Vista5 from '@/views/Vista5.vue'
import Vista6 from '@/views/Vista6.vue'
import HomeInternal from '@/views/HomeInternal.vue'

Vue.use(VueRouter)

const routes = [

	/**
	 * ====================================================
	 * INICIO
	 * ====================================================
	 */
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			private: true,
			fluid: false,
			title: 'Inicio',
		}
	},
	{
		path: '/empresa',
		name: 'Contractor',
		component: Contractor,
		meta: {
			private: true,
			fluid: false,
			title: 'Empresa',
		}
	},
	{
		path: '/ingresar',
		name: 'Login',
		component: Login,
		meta: {
			private: false,
			fluid: false,
			title: 'Iniciar Sesión',
		}
	},
	{
		path: '/salir',
		name: 'Logout',
		component: Logout,
		meta: {
			private: true,
			fluid: false,
			title: 'Salir',
		}
	},
	{
		path: '/recuperar',
		name: 'Recover',
		component: Recover,
		meta: {
			private: false,
			fluid: false,
			title: 'Recover',
		}
	},
	{
		path: '/activar/:token',
		name: 'Reset',
		component: Reset,
		meta: {
			private: false,
			fluid: false,
			title: 'Reset',
		}
	},


	/**
	 * ====================================================
	 * PÚBLICO
	 * ====================================================
	 */
	{
		path: '/visita/:token',
		name: 'PassToken',
		component: PassToken,
		meta: {
			multi: true,
			fluid: false,
			title: 'Pase de Visita',
		}
	},
	{
		path: '/transportista/:token',
		name: 'CargoPassToken',
		component: CargoPassToken,
		meta: {
			multi: true,
			fluid: false,
			title: 'Pase de Transportista',
		}
	},
	{
		path: '/corporativo/:token',
		name: 'CorporatePassToken',
		component: CorporatePassToken,
		meta: {
			multi: true,
			fluid: false,
			title: 'Pase Corporativo',
		}
	},
	{
		path: '/reporte/diario/:token',
		name: 'PublicDailyReport',
		component: PublicDailyReport,
		meta: {
			multi: true,
			fluid: false,
			title: 'Reporte Diario',
		}
	},
	{
		path: '/reporte/acreditacion/:token',
		name: 'RequirementsReport',
		component: RequirementsReport,
		meta: {
			multi: true,
			fluid: false,
			title: 'Acreditación',
		}
	},


	/**
	 * ====================================================
	 * SCA 3.0
	 * ====================================================
	 */
	{
		path: '/grids',
		name: 'Grids',
		meta: {
			private: true,
			fluid: false,
			title: 'Vista de Pájaro',
		},
		component: Grids
	},
	{
		path: '/sistema/usuarios/aprobadores',
		name: 'Approvers',
		meta: {
			private: true,
			fluid: false,
			title: 'Aprobadores',
		},
		component: Approvers
	},
	{
		path: '/colas',
		name: 'Queues',
		meta: {
			private: true,
			fluid: true,
			title: 'Colas de Revisión',
		},
		component: Queues
	},
	{
		path: '/acreditacion2',
		name: 'Accreditation',
		meta: {
			private: true,
			fluid: false,
			title: 'Acreditación',
		},
		component: Accreditation
	},


	/**
	 * ====================================================
	 * EMPRESAS
	 * ====================================================
	 */
	{
		path: '/empresas/contratistas',
		name: 'Contractors',
		meta: {
			private: true,
			fluid: false,
			title: 'Empresas Contratistas',
		},
		component: Contractors
	},
	{
		path: '/empresas/subcontratistas',
		name: 'Subcontractors',
		meta: {
			private: true,
			fluid: false,
			title: 'Empresas Subcontratistas',
		},
		component: Subcontractors
	},

	/**
	 * ====================================================
	 * SERVICIOS
	 * ====================================================
	 */
	{
		path: '/servicios/ordenes-servicio',
		name: 'ServiceOrders',
		meta: {
			private: true,
			fluid: false,
			title: 'Órdenes de Servicio',
			type: 1
		},
		component: Services
	},
	{
		path: '/servicios/contratos',
		name: 'Contracts',
		meta: {
			private: true,
			fluid: false,
			title: 'Contratos',
			type: 2
		},
		component: Services
	},
	{
		path: '/servicios/garantias',
		name: 'Warranties',
		meta: {
			private: true,
			fluid: false,
			title: 'Garantías',
			type: 3
		},
		component: Services
	},
	{
		path: '/servicios/subcontratos',
		name: 'Subcontracts',
		meta: {
			private: true,
			fluid: false,
			title: 'Subcontratos',
			type: 4
		},
		component: Services
	},

	/**
	 * ====================================================
	 * TRABAJADORES
	 * ====================================================
	 */
	{
		path: '/trabajadores/mandante',
		name: 'InternalWorkers',
		meta: {
			private: true,
			fluid: false,
			title: 'Trabajadores HMC',
			type: 1
		},
		component: Workers
	},
	{
		path: '/trabajadores/contratistas',
		name: 'ContractorWorkers',
		meta: {
			private: true,
			fluid: false,
			title: 'Trabajadores Contratistas',
			type: 2
		},
		component: Workers
	},
	{
		path: '/trabajadores/subcontratistas',
		name: 'SubcontractorWorkers',
		meta: {
			private: true,
			fluid: false,
			title: 'Trabajadores Subcontratistas',
			type: 3
		},
		component: Workers
	},

	/**
	 * ====================================================
	 * VEHICULOS
	 * ====================================================
	 */
	{
		path: '/vehiculos/mandante',
		name: 'InternalVehicles',
		meta: {
			private: true,
			fluid: false,
			title: 'Vehículos HMC',
			type: 1
		},
		component: Vehicles
	},
	{
		path: '/vehiculos/contratistas',
		name: 'ContractorVehicles',
		meta: {
			private: true,
			fluid: false,
			title: 'Vehículos Contratistas',
			type: 2
		},
		component: Vehicles
	},
	{
		path: '/vehiculos/subcontratistas',
		name: 'SubcontractorVehicles',
		meta: {
			private: true,
			fluid: false,
			title: 'Vehículos Subcontratistas',
			type: 3
		},
		component: Vehicles
	},

	/**
	 * ====================================================
	 * PASSES
	 * ====================================================
	 */
	{
		path: '/pases',
		name: 'Passes',
		meta: {
			private: true,
			fluid: false,
			title: 'Pases de Visita',
		},
		component: Passes
	},
	{
		path: '/pases/transportistas',
		name: 'CargoPasses',
		meta: {
			private: true,
			fluid: false,
			title: 'Pases de Transportista',
		},
		component: CargoPasses
	},
	{
		path: '/pases/corporativos',
		name: 'CorporatePasses',
		meta: {
			private: true,
			fluid: false,
			title: 'Pases Corporativo',
		},
		component: CorporatePasses
	},

	/**
	 * ====================================================
	 * REPORTES
	 * ====================================================
	 */
	{
		path: '/reportes',
		name: 'Reports',
		meta: {
			private: true,
			fluid: false,
			title: 'Reportes',
		},
		component: Reports
	},
	{
		path: '/reportes/asistencia',
		name: 'Attendance',
		meta: {
			private: true,
			fluid: true,
			title: 'Asistencia',
		},
		component: Attendance
	},
	{
		path: '/reportes/monitor',
		name: 'Monitor',
		meta: {
			private: true,
			fluid: false,
			title: 'Monitor',
		},
		component: Monitor
	},
	{
		path: '/reportes/en-faena',
		name: 'OnSite',
		meta: {
			private: true,
			fluid: false,
			title: 'En Faena',
		},
		component: OnSite
	},
	{
		path: '/reportes/estadisticas',
		name: 'Stats',
		meta: {
			private: true,
			fluid: false,
			title: 'Estadísticas',
		},
		component: Stats
	},
	{
		path: '/reportes/vencimientos',
		name: 'Expirations',
		meta: {
			private: true,
			fluid: false,
			title: 'Vencimientos',
		},
		component: Expirations
	},
	{
		path: '/reportes/alcotest',
		name: 'RandomTesting',
		meta: {
			private: true,
			fluid: false,
			title: 'Sorteo Alcotest',
		},
		component: RandomTesting
	},
	
	{
		path: '/reportes/estadisticas/contratistas',
		name: 'StatsContractor',
		meta: {
			private: true,
			fluid: false,
			title: 'Estadísticas'
		},
		component: StatsContractor
	},
	{
		path: '/reportes/estadisticas/subcontratistas',
		name: 'StatsSubcontractor',
		meta: {
			private: true,
			fluid: false,
			title: 'Estadísticas'
		},
		component: StatsSubcontractor
	},
	{
		path: '/reportes/estadisticas/trabajadores/contratistas',
		name: 'StatsContractWorker',
		meta: {
			private: true,
			fluid: false,
			title: 'Estadísticas'
		},
		component: StatsContractWorker
	},
	{
		path: '/reportes/estadisticas/trabajadores/subcontratistas',
		name: 'StatsSubcontractWorker',
		meta: {
			private: true,
			fluid: false,
			title: 'Estadísticas'
		},
		component: StatsSubcontractWorker
	},
	{
		path: '/reportes/estadisticas/vehiculos/contratistas',
		name: 'StatsContractVehicle',
		meta: {
			private: true,
			fluid: false,
			title: 'Estadísticas'
		},
		component: StatsContractVehicle
	},
	{
		path: '/reportes/estadisticas/vehiculos/subcontratistas',
		name: 'StatsSubcontractVehicle',
		meta: {
			private: true,
			fluid: false,
			title: 'Estadísticas'
		},
		component: StatsSubcontractVehicle
	},
	{
		path: '/reportes/estadisticas/ordenes-servicio',
		name: 'StatsService',
		meta: {
			private: true,
			fluid: false,
			title: 'Estadísticas'
		},
		component: StatsService
	},
	{
		path: '/reportes/estadisticas/contratos',
		name: 'StatsContract',
		meta: {
			private: true,
			fluid: false,
			title: 'Estadísticas'
		},
		component: StatsContract
	},
	{
		path: '/reportes/estadisticas/garantias',
		name: 'StatsWarranty',
		meta: {
			private: true,
			fluid: false,
			title: 'Estadísticas'
		},
		component: StatsWarranty
	},
	{
		path: '/reportes/estadisticas/subcontratos',
		name: 'StatsSubcontract',
		meta: {
			private: true,
			fluid: false,
			title: 'Estadísticas'
		},
		component: StatsSubcontract
	},
	{
		path: '/reportes/estadisticas/controles',
		name: 'StatsControl',
		meta: {
			private: true,
			fluid: false,
			title: 'Estadísticas'
		},
		component: StatsControl
	},
	{
		path: '/reportes/estadisticas/aprobadores',
		name: 'StatsAction',
		meta: {
			private: true,
			fluid: false,
			title: 'Estadísticas'
		},
		component: StatsAction
	},
	{
		path: '/reportes/diario',
		name: 'DailyReport',
		component: DailyReport,
		meta: {
			private: true,
			fluid: false,
			title: 'Reporte Diario',
		}
	},

	/**
	 * ====================================================
	 * SISTEMA
	 * ====================================================
	 */
	{
		path: '/sistema',
		name: 'System',
		meta: {
			private: true,
			fluid: false,
			title: 'Sistema',
		},
		component: System
	},
	{
		path: '/sistema/usuarios',
		name: 'Users',
		meta: {
			private: true,
			fluid: false,
			title: 'Usuarios',
		},
		component: Users
	},
	{
		path: '/sistema/turnos',
		name: 'Shifts',
		meta: {
			private: true,
			fluid: false,
			title: 'Turnos',
		},
		component: Shifts
	},
	{
		path: '/sistema/usuarios/actividad',
		name: 'UserLogs',
		meta: {
			private: true,
			fluid: true,
			title: 'Actividad de Usuarios',
		},
		component: UserLogs
	},
	{
		path: '/sistema/corrector-controles',
		name: 'ControlFixer',
		meta: {
			private: true,
			fluid: false,
			title: 'Corrector de Controles',
		},
		component: ControlFixer
	},
	{
		path: '/sistema/requisitos',
		name: 'RequirementsManager',
		meta: {
			private: true,
			fluid: false,
			title: 'Requisitos de Acreditación',
		},
		component: RequirementsManager
	},
	{
		path: '/sistema/parametros',
		name: 'GlobalParameters',
		meta: {
			private: true,
			fluid: false,
			title: 'Parámetros Globales',
		},
		component: GlobalParameters
	},

	/**
	 * ====================================================
	 * SISTEMA
	 * ====================================================
	 */
	{
		path: '/ayuda',
		name: 'Support',
		meta: {
			private: true,
			fluid: false,
			title: 'Support',
		},
		component: Support
	},

	/**
	 * ====================================================
	 * CUENTA
	 * ====================================================
	 */
	{
		path: '/cuenta',
		name: 'Account',
		meta: {
			private: true,
			fluid: false,
			title: 'Mi Cuenta',
		},
		component: Account
	},

	/**
	 * ====================================================
	 * CUENTA
	 * ====================================================
	 */
	{
		path: '/upgrade_dcs',
		name: 'UpgradeDCS',
		meta: {
			private: true,
			fluid: true,
			title: 'Acreditación para Mantención Mayor / Upgrade DCS',
		},
		component: UpgradeDCS
	},

	{
		path: '/acreditacion/abastecimiento',
		name: 'Vista1',
		meta: {
			private: true,
			fluid: true,
			title: 'Vista1',
		},
		component: Vista1
	},

	{
		path: '/acreditacion/rrhh',
		name: 'Vista2',
		meta: {
			private: true,
			fluid: true,
			title: 'Vista2',
		},
		component: Vista2
	},

	{
		path: '/acreditacion/dpr',
		name: 'Vista3',
		meta: {
			private: true,
			fluid: true,
			title: 'Vista3',
		},
		component: Vista3
	},

	{
		path: '/vista4',
		name: 'Vista4',
		meta: {
			private: true,
			fluid: true,
			title: 'Vista4',
		},
		component: Vista4
	},

	{
		path: '/vista5',
		name: 'Vista5',
		meta: {
			private: true,
			fluid: true,
			title: 'Vista5',
		},
		component: Vista5
	},

	{
		path: '/vista6',
		name: 'Vista6',
		meta: {
			private: true,
			fluid: true,
			title: 'Vista6',
		},
		component: Vista6
	},

	{
		path: '/acreditacion',
		name: 'HomeInternal',
		meta: {
			private: true,
			fluid: true,
			title: 'Acreditación',
		},
		component: HomeInternal
	},
]

const router = new VueRouter({
	base: process.env.BASE_URL,
	routes
})

export default router
