<template>
	<div>

		<b-container>

			<p class="text-size-2 mb-3 fw-7">Abastecimiento y Contratos</p>
			
			<b-card no-body class="mb-5">
				
				<b-table
					:items="test"
					:fields="[
						{ key: 'company', label: 'Empresa', sortable: false },
						{ key: 'services', label: 'Servicios', sortable: false },
					]"
					hover
				>
					<template #cell(company)="data">
						<div>
							<p class="mb-0 nowrap text-size-12">
								<span class="mr-1" style="font-weight: 500">{{ data.item.short_name }}</span>
								<i class="fas fa-check-circle text-success" v-if="data.item.req_done == 17"></i>
								<i class="fas fa-times-circle text-danger" v-else></i>
							</p>
							<p class="mb-1 text-size-11 nowrap">{{ parseIdentifier(data.item.identifier) }}</p>
							<p class="mb-1" v-if="data.item.req_done == 17">
								<b-button variant="outline-success" size="sm">
									Ver Requisitos
								</b-button>
							</p>
							<p class="mb-1" v-if="data.item.req_done < 17">
								<b-button variant="danger" size="sm">
									{{ 17 - data.item.req_done }} Requisitos Pendientes
								</b-button>
							</p>
							<p class="mb-0 text-lowercase text-size-09 text-muted">
								<i class="far fa-envelope mr-1"></i>
								<span>{{ data.item.email }}</span>
							</p>
							<p class="mb-0 text-lowercase text-size-09 text-muted">
								<i class="far fa-phone mr-1"></i>
								<span>{{ data.item.phone }}</span>
							</p>
						</div>
					</template>
					<template #cell(services)="data">
						<b-list-group>
							<b-list-group-item v-for="(item, index) in data.value" :key="index" class="px-2 py-2" button>
								<b-row>
									<b-col>
										<p class="mb-0">
											<span v-if="item.req_done < 9 && item.type != 3 || item.req_done < 3 && item.type == 3" class="text-danger">
												<strong>{{ item.cod }}</strong>
											</span>
											<span v-else class="text-success">
												<strong>{{ item.cod }}</strong>
											</span>
											<b-badge class="text-size-09 ml-2 text-uppercase" variant="light">
												{{ item.type == 1 ? 'Orden de Servicio' : item.type == 2 ? 'Contrato' : item.type == 3 ? 'Garantía' : 'Subcontrato' }}
											</b-badge>
										</p>
										<p class="mb-0 text-size-09 text-uppercase">{{ item.comments }}</p>
										<p class="mb-0 text-size-08 text-uppercase text-muted">
											<i class="far fa-user mr-1"></i>
											<span>{{ item.administrator.person.names.split(' ')[0] }} {{ item.administrator.person.primary_last_name }} / {{ item.administrator.position }}</span>
										</p>
									</b-col>
									<b-col class="nowrap" sm="auto">
										<p class="mb-1 text-right text-size-09">
											<span>{{ item.start_date }}</span>
											<i class="fas fa-chevron-right text-size-07 mx-1"></i>
											<span>{{ item.real_end_date }}</span>
										</p>
										<div v-if="item.req_done < 9 && item.type != 3 || item.req_done < 3 && item.type == 3">
											<b-button variant="danger" size="sm" block class="py-2">
												<i class="fas fa-fw fa-times mr-1"></i>
												<span>{{ 9 - item.req_done }}</span> Requisitos Pendientes
											</b-button>
										</div>
										<div v-else>
											<b-button variant="outline-success" size="sm" block class="py-2">
												<i class="fas fa-fw fa-check mr-1"></i>
												<span>Ver Requisitos</span>
											</b-button>
										</div>
									</b-col>
								</b-row>
							</b-list-group-item>
						</b-list-group>
					</template>
				</b-table>

			</b-card>
			
		</b-container>


	</div>
</template>

<script>
export default {
	name: 'Vista3',
	components: {
	},
	data() {
		return {
			loading: true,
			test: null,
		}
	},
	created() {
		this.load()
	},
	mounted() {
	},
	computed: {
	},
	methods: {
		reload() {
			this.load()
		},
		load() {
			this.loading = true
			this.$api.get('vista3')
			.then(response => {
				this.loading = false
				console.log(response.data)
				this.test = response.data
			})
			.catch(error => {
				this.loading = false
				console.log(error)
			})
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
	}
}
</script>

<style>
</style>
