<template>
	<div id="app">
		<Header v-if="!login && isDev == false"></Header>
		<b-container :fluid="!fluid?'xl':''" class="mt-4">
			<Navigation v-if="session && isDev == false"></Navigation>
			<router-view></router-view>
			<Footer v-if="!logout && isDev == false"></Footer>
		</b-container>
		<SystemUpdate></SystemUpdate>
	</div>
</template>
<script>
import Navigation from '@/components/core/Navigation.vue'
import Footer from '@/components/core/Footer.vue'
import Header from '@/components/core/Header.vue'
import SystemUpdate from '@/components/core/SystemUpdate.vue'

export default {
	name: 'AccessUI',
	components: {
		Header,
		Navigation,
		Footer,
		SystemUpdate
	},
	created() {
		this.$api.get('system/build')
		.then(response => {
			if (response.data > this.$store.state.build) {
				this.$bvModal.show('system-update')
			}
		})
		if (this.session) {
			this.$store.dispatch('get_session_privileges')
		}
	},
	computed: {
		fluid() {
			return this.$route.meta.fluid
		},
		session() {
			return this.$store.getters.session
		},
		login() {
			return this.$route.name === 'Login' || this.$route.name === 'Recover' || this.$route.name === 'Reset'
		},
		logout() {
			return this.$route.name === 'Logout'
		},
		isDev() {
			return this.$route.name == 'HomeInternal' || this.$route.name == 'Vista1' || this.$route.name == 'Vista2' || 
			this.$route.name == 'Vista3' || this.$route.name == 'Vista4' || this.$route.name == 'Vista5' || this.$route.name == 'Vista6'  ? true : false
		},
	},
	methods: {
		goTo(route_name) {
			if (route_name == this.$route.name) return false;
			this.$router.push({ name: route_name })
		},
	}
}
</script>

<style lang="scss">
</style>
