<template>
	<Unauthorized v-if="getters.session_loading"></Unauthorized>
	<div v-else>
		<b-button class="mb-3" variant="danger" @click="goTo('HomeInternal')">Versión Desarrollo</b-button>
		<b-card body-class="pt-3 px-3 pb-0">
			
			<b-row>

				<router-link to="/empresa" custom v-slot="{ navigate }" v-if="!getters.internal">
					<b-col lg="4" md="6" sm="12" class="mb-3">
						<b-button block variant="light" class="module py-3" @click="navigate" @keypress.enter="navigate" role="link">
							<b-img src="img/icon_modules/companies.svg" class="icon mb-2"></b-img>
							<h5 class="mb-1">Gestión de Empresa</h5>
							<p class="m-0 text-muted">Documentación Electrónica de su Empresa para prestar servicios en HMC Michilla</p>
						</b-button>
					</b-col>
				</router-link>

				<b-col lg="4" md="6" sm="12" class="mb-3" v-if="getters.prv_contractors || getters.prv_subcontractors">
					<b-button block variant="light" class="module py-3" @click="$bvModal.show('company-selector')">
						<b-img src="img/icon_modules/companies.svg" class="icon mb-2"></b-img>
						<h5 class="mb-1">Gestión de Empresas</h5>
						<p class="m-0 text-muted">Documentación Electrónica de Empresas Contratistas y Subcontratistas</p>
					</b-button>
				</b-col>

				<b-col lg="4" md="6" sm="12" class="mb-3" v-if="getters.prv_service_orders || getters.prv_contracts || getters.prv_warranties || getters.subcontracts">
					<b-button block variant="light" class="module py-3" @click="$bvModal.show('service-selector')">
						<b-img src="img/icon_modules/services.svg" class="icon mb-2"></b-img>
						<h5 class="mb-1">Gestión de Servicios</h5>
						<p class="m-0 text-muted">Documentación Electrónica de Órdenes de Servicio, Contratos, Subcontratos y Garantías</p>
					</b-button>
				</b-col>

				<b-col lg="4" md="6" sm="12" class="mb-3" v-if="getters.prv_internal_workers || getters.prv_contractor_workers || getters.prv_subcontractor_workers">
					<b-button block variant="light" class="module py-3" @click="$bvModal.show('worker-selector')">
						<b-img src="img/icon_modules/workers.svg" class="icon mb-2"></b-img>
						<h5 class="mb-1">Gestión de Personal</h5>
						<p class="m-0 text-muted">Documentación Electrónica de Trabajadores y Conductores</p>
					</b-button>
				</b-col>

				<b-col lg="4" md="6" sm="12" class="mb-3" v-if="getters.prv_internal_vehicles || getters.prv_contractor_vehicles || getters.prv_subcontractor_vehicles">
					<b-button block variant="light" class="module py-3" @click="$bvModal.show('vehicle-selector')">
						<b-img src="img/icon_modules/vehicles.svg" class="icon mb-2"></b-img>
						<h5 class="mb-1">Gestión de Vehículos</h5>
						<p class="m-0 text-muted">Documentación Electrónica de Maquinaria Pesada y Vehículos Livianos</p>
					</b-button>
				</b-col>

				<b-col lg="4" md="6" sm="12" class="mb-3" v-if="getters.prv_passes || getters.prv_cargo_passes || getters.prv_corporate_passes">
					<b-button block variant="light" class="module py-3" @click="$bvModal.show('pass-selector')">
						<b-img src="img/icon_modules/passes.svg" class="icon mb-2"></b-img>
						<h5 class="mb-1">Pases de Acceso</h5>
						<p class="m-0 text-muted">Gestión de pases de acceso para ingresar a Faena Michilla como Visita o Transportista.</p>
					</b-button>
				</b-col>

				<router-link to="/reportes" custom v-slot="{ navigate }" v-if="getters.prv_reports">
					<b-col lg="4" md="6" sm="12" class="mb-3">
						<b-button block variant="light" class="module py-3" @click="navigate" @keypress.enter="navigate" role="link">
							<b-img src="img/icon_modules/reports.svg" class="icon mb-2"></b-img>
							<h5 class="mb-1">Reportes & Actividad</h5>
							<p class="m-0 text-muted">Informes en línea, descargables en formato Excel y consulta de otros datos</p>
						</b-button>
					</b-col>
				</router-link>

				<router-link to="/cuenta" custom v-slot="{ navigate }">
					<b-col lg="4" md="6" sm="12" class="mb-3">
						<b-button block variant="light" class="module py-3" @click="navigate" @keypress.enter="navigate" role="link">
							<b-img src="img/icon_modules/account.svg" class="icon mb-2"></b-img>
							<h5 class="mb-1">Cuenta de Usuario</h5>
							<p class="m-0 text-muted">Vea información de su cuenta de usuario y cambie su contraseña</p>
						</b-button>
					</b-col>
				</router-link>

				<router-link to="/sistema" custom v-slot="{ navigate }" v-if="getters.prv_system">
					<b-col lg="4" md="6" sm="12" class="mb-3">
						<b-button block variant="light" class="module py-3" @click="navigate" @keypress.enter="navigate" role="link">
							<b-img src="img/icon_modules/settings.svg" class="icon mb-2"></b-img>
							<h5 class="mb-1">Configuración</h5>
							<p class="m-0 text-muted">Configuración de parámetros globales del Sistema de Control de Acceso</p>
						</b-button>
					</b-col>
				</router-link>

			</b-row>

		</b-card>
	</div>
</template>

<script>
import Unauthorized from '@/components/core/Unauthorized.vue'
export default {
	name: 'Home',
	components: {
		Unauthorized
	},
	data() {
		return {
		}
	},
	mounted() {
	},
	computed: {
		getters() {
			return this.$store.getters
		},
		state() {
			return this.$store.getters
		},
	},
	methods: {
		isRoute(name) {
			return this.$route.name === name ? true : false
		},
		isRouteVariant(name) {
			return this.$route.name === name ? 'primary' : 'primary'
		},
		company_type(type) {
			if (type === 1) {
				this.$router.push({ name: 'Contractors' })
			}
			else if (type === 2) {
				this.$router.push({ name: 'Subcontractors' })
			}
			this.$bvModal.hide('company-selector')
			this.$store.state.subroutes.companies = type
		},
		service_type(type) {
			if (type === 1) {
				this.$router.push({ name: 'ServiceOrders' })
			}
			else if (type === 2) {
				this.$router.push({ name: 'Contracts' })
			}
			else if (type === 3) {
				this.$router.push({ name: 'Warranties' })
			}
			else if (type === 4) {
				this.$router.push({ name: 'Subcontracts' })
			}
			this.$bvModal.hide('service-selector')
			this.$store.state.subroutes.services = type
		},
		worker_type(type) {
			if (type === 1) {
				this.$router.push({ name: 'InternalWorkers' })
			}
			else if (type === 2) {
				this.$router.push({ name: 'ContractorWorkers' })
			}
			else if (type === 3) {
				this.$router.push({ name: 'SubcontractorWorkers' })
			}
			this.$bvModal.hide('worker-selector')
			this.$store.state.subroutes.workers = type
		},
		vehicle_type(type) {
			if (type === 1) {
				this.$router.push({ name: 'InternalVehicles' })
			}
			else if (type === 2) {
				this.$router.push({ name: 'ContractorVehicles' })
			}
			else if (type === 3) {
				this.$router.push({ name: 'SubcontractorVehicles' })
			}
			this.$bvModal.hide('vehicle-selector')
			this.$store.state.subroutes.vehicles = type
		},
		pass_type(type) {
			if (type === 1) {
				this.$router.push({ name: 'Passes' })
			}
			else if (type === 2) {
				this.$router.push({ name: 'CargoPasses' })
			}
			else if (type === 3) {
				this.$router.push({ name: 'CorporatePasses' })
			}
			this.$bvModal.hide('pass-selector')
		},
		goTo(route_name) {
			if (route_name == this.$route.name) return false;
			this.$router.push({ name: route_name })
		},
	}
}
</script>

<style scoped>
.module .icon {
	height: 56px;
}
.module {
	border: 1px solid #ddd;
}
.module:hover {
	border: 1px solid #999;
}
</style>
